import React from 'react';

import useCurrentUser from 'src/hooks/useCurrentUser';
import BuyerProfile from './BuyerProfile';
// import VendorProfile from './VendorProfile';
import { useLocation, useHistory } from 'react-router-dom';

// type ProfileProps = {
//     isOnboarding: boolean;
// }
interface stateType {
    submitLabel: string;
    isOnboarding: boolean;
}

export const Profile = () => {
    const { isBuyer, isVendor } = useCurrentUser();
    const { state: locationState } = useLocation<stateType>();
    const history = useHistory();

    const handleUpdateUser = () => {
        // if (locationState && locationState.isOnboarding && isBuyer) {
        //     return () => {
        //         history.push('/jobs/new');
        //     };
        // }

        history.push('/');
    };
    return (
        <>
            {isBuyer && <BuyerProfile onUpdateUser={handleUpdateUser} />}
            {/* {isVendor && <VendorProfile />} */}
        </>
    );
};
export default Profile;
