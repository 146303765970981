import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

// import { fetchProjects } from 'src/domains/marketplace/api/projects';
// import { fetchUserActivities } from 'src/domains/marketplace/api/activities';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { RFQList } from '../RFQ/RFQListByProject';
import useProjects from 'src/hooks/useProjects';
import { IconButton, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            // textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);

// const DashboardProject = (props: any) => {
//     return (
//         <div>
//             <RouterLink to={`/job/${props.project.id}`}>{props.project.name}</RouterLink>
//         </div>
//     );
// };

type Props = unknown;
export const BuyerDashboard = (props: Props) => {
    const classes = useStyles();
    // const [projects, setProjects] = React.useState<Array<any> | null>();
    const { setProject } = useProjects();
    const { currentUser } = useCurrentUser();
    const { t } = useTranslation();
    // const history = useHistory();

    // const loadActivities = async () => {
    //     const data = await fetchUserActivities(currentUser);
    // };
    // const loadProjects = async () => {
    //     const data = await fetchProjects(currentUser);
    //     setProjects(data);
    // };

    React.useEffect(() => {
        // loadProjects();
        if (currentUser) {
            // loadActivities();
            setProject(null); // reset value for inbox
        }
    }, [currentUser]);

    return (
        <div className={classes.root}>
            {currentUser && !!currentUser.firstName && (
                <div style={{ marginBottom: -15, marginLeft: -12 }}>
                    <IconButton>
                        <AccountCircleIcon />
                    </IconButton>
                    <b>
                        {currentUser.firstName} {currentUser.lastName}
                    </b>{' '}
                    ({currentUser.company?.name})
                </div>
            )}

            <Typography variant="h4" style={{ marginBottom: 30 }}>
                {t('Dashboard')}
            </Typography>

            <RFQList />
        </div>
    );
};
