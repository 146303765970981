import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Link, useParams, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Dialog from '@material-ui/core/Dialog';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import { groupBy } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { listRfQs } from 'src/graphql/queries';
import { createRfq } from 'src/graphql/mutations';
import { updateRfq } from 'src/graphql/mutations';
import { ListRfQsQuery } from 'src/API';
import { getUUID } from 'src/helpers';
import { formatDate } from 'src/helpers';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { getCurrentUserProjectMembers } from 'src/domains/marketplace/api/projects';
import ProjectName from '../ProjectName';
import { sortByDate } from 'src/helpers';
import { createRFQ as apiCreateRFQ } from 'src/domains/marketplace/api/projects';
import { RFQStatus } from './RFQStatus';
import { RFQ_STATUS } from 'src/constants';
import { truncateSync } from 'fs';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

type Props = {
    // projectId: string;
};
export const RFQList: React.FC<Props> = () => {
    const [rfqs, setRFQs] = useState<ListRfQsQuery | undefined | null>(undefined);
    const [loading, setLoading] = useState(true);
    const { projectId } = useParams<{ projectId: string }>();
    const history = useHistory();
    const classes = useStyles();
    const { currentUser, isAdmin } = useCurrentUser();
    const { t } = useTranslation();

    async function fetchRFQs() {
        // let filter = {
        //     projectId: { eq: projectId },
        // };
        // const projectMember = await getCurrentUserProjectMember(currentUser, projectId);
        // const projectMembers = await getCurrentUserProjectMembers(currentUser);

        // if (!projectMembers || projectMembers.length === 0) {
        //     setLoading(false);
        //     setRFQs(null);
        //     return;
        // }

        // no need : done with graphql groups permissions
        // const filter = {
        //     or: projectMembers.map((x: any) => {
        //         return {
        //             projectMemberIds: {
        //                 contains: x.id,
        //             },
        //         };
        //     }),
        // };
        const filter = {
            isRevision: { ne: true },
        };
        try {
            const response = (await API.graphql(
                graphqlOperation(listRfQs, {
                    filter: filter,
                }),
            )) as {
                data: ListRfQsQuery;
            };
            setRFQs(response.data);
        } catch (err) {
            console.log('error fetching rfqs', err);
        } finally {
            setLoading(false);
        }
    }

    // async function createRFQRequest(projectId: string): Promise<string> {
    //     const response = (await API.graphql(
    //         graphqlOperation(createRfq, {
    //             input: {
    //                 id: getUUID(),
    //                 version: 1,
    //                 projectId: projectId,
    //                 creatorId: currentUser.id,
    //             },
    //         }),
    //     )) as {
    //         data: any; //GetRfqQuery;
    //     };
    //     if (response) {
    //         return response?.data?.createRFQ?.id || '';
    //     } else {
    //         return '';
    //     }
    // }

    useEffect(() => {
        if (currentUser) fetchRFQs();
    }, [currentUser]);

    // const handleCreateRFQ = async () => {
    //     // const id = await createRFQRequest();
    //     // history.push(`/projects/${projectId}/rfqs/${id}`);
    //     history.push(`/rfq/new`);
    // };

    const handleProjectSelect = (id: string): void => {
        history.push(`/job/${id}`);
    };

    const handleCreateProject = async () => {
        history.push(`/jobs/new`);
    };

    const handleCreateRfqForProject = async (project: any) => {
        const rfq = await apiCreateRFQ(currentUser, project.id);
        history.push(`/rfq/${rfq.id}`);
    };

    // https://stackoverflow.com/questions/51052896/groupby-first-then-sort-array-based-on-name-with-lodash
    const groupedByProject = groupBy(rfqs?.listRFQs?.items as any[], (x) => x?.project?.id);
    // mapValues(
    //     groupBy(rfqs?.listRFQs?.items as any[], (x) => x?.project?.id),
    //     (v) => sortByDate(v, 'createdAt', 'DESC'),
    // );
    // console.log(groupedByProject);
    const projectsCount = Object.keys(groupedByProject).length;
    const canCreateProject = !!currentUser?.firstName || !!currentUser?.lastName;

    return (
        <div>
            {projectsCount > 0 && (
                <Badge color="secondary" badgeContent={projectsCount}>
                    <Typography variant="h5">
                        <Trans t={t}>Jobs</Trans>
                    </Typography>
                </Badge>
            )}
            {!canCreateProject && (
                // <Grid container justify="center">
                <div>
                    <Typography paragraph>
                        <Trans t={t}>You need a valid profile</Trans>
                    </Typography>

                    <Button variant="contained" size="large" color="primary" onClick={() => history.push('/profile')}>
                        <Trans t={t}>Fill in my profile</Trans>
                    </Button>
                </div>
                // </Grid>
            )}

            <TableContainer>
                {!loading && projectsCount > 0 && (
                    <Table className={classes.table} aria-label="Jobs">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Trans t={t}>Job ID</Trans>
                                </TableCell>
                                <TableCell align="left">
                                    <Trans t={t}>Job Name</Trans>
                                </TableCell>
                                <TableCell align="left">
                                    <Trans t={t}>Creation date</Trans>
                                </TableCell>
                                {isAdmin && (
                                    <TableCell align="left">
                                        <Trans t={t}>Created by</Trans>
                                    </TableCell>
                                )}
                                <TableCell align="left">
                                    <Trans t={t}>RFQs</Trans>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(groupedByProject)
                                // .sort(([, a], [, b]) => {
                                //     console.log('a', a, 'b', b);
                                //     if (a.length === 0 || b.length === 0) return 0;
                                //     const maxA = sortByDate(a, 'createdAt', 'DESC').pop().createdAt;
                                //     const maxB = sortByDate(b, 'createdAt', 'DESC').pop().createdAt;
                                //     return new Date(maxA).getTime() - new Date(maxB).getTime();
                                // })
                                .map(([projectId, projectRfqs]) => {
                                    const project = projectRfqs && projectRfqs![0]?.project;
                                    return (
                                        <TableRow key={project!.id}>
                                            <TableCell
                                                component="th"
                                                style={{ cursor: 'pointer' }}
                                                scope="row"
                                                onClick={() => handleProjectSelect(project!.id)}
                                            >
                                                {project!.id}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleProjectSelect(project!.id)}
                                            >
                                                <ProjectName project={project} />
                                            </TableCell>
                                            <TableCell align="left">{formatDate(project!.createdAt, 'L LT')}</TableCell>
                                            {isAdmin && (
                                                <TableCell align="left">
                                                    <div>
                                                        <a href={`mailto:${project.creator?.email}`}>
                                                            {project.creator?.firstName} {project.creator?.lastName}
                                                        </a>
                                                    </div>
                                                    {project.creator?.company?.name}
                                                </TableCell>
                                            )}
                                            <TableCell align="left">
                                                <Table className={classes.table} aria-label="rfqs" size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Trans t={t}>RFQ ID</Trans>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Trans t={t}>Products</Trans>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Trans t={t}>Sizing</Trans>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Trans t={t}>Revision</Trans>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Trans t={t}>Creation date</Trans>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Trans t={t}>Status</Trans>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {sortByDate(projectRfqs as any[], 'createdAt', 'DESC')?.map(
                                                            (rfq) =>
                                                                rfq && (
                                                                    <RfqRow rfq={rfq} key={rfq.id} isAdmin={isAdmin} />
                                                                ),
                                                        )}
                                                    </TableBody>
                                                </Table>

                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    color="primary"
                                                    style={{ marginTop: 16 }}
                                                    onClick={() => handleCreateRfqForProject(project)}
                                                >
                                                    <Trans t={t}>Create a new RFQ</Trans>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                )}

                {!loading && projectsCount === 0 && (
                    <Typography paragraph>Créez un Job pour obtenir un chiffrage </Typography>
                )}

                {canCreateProject && (
                    <Grid container justify="center">
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleCreateProject}
                            style={{ margin: 32 }}
                        >
                            <Trans t={t}>Create a new Job</Trans>
                        </Button>
                    </Grid>
                )}
            </TableContainer>
        </div>
    );
};

// export interface CreateRfqDialogProps {
//     open: boolean;
//     selectedValue: string;
//     onClose: (value: string) => void;
// }

// function CreateRfqDialog(props: CreateRfqDialogProps) {
//     const classes = useStyles();
//     const { onClose, selectedValue, open } = props;

//     const handleClose = () => {
//         onClose(selectedValue);
//     };

//     const handleListItemClick = (value: string) => {
//         onClose(value);
//     };

//     return (
//         <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
//             <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle>
//             <List>
//                 {emails.map((email) => (
//                     <ListItem button onClick={() => handleListItemClick(email)} key={email}>
//                         <ListItemText primary={email} />
//                     </ListItem>
//                 ))}
//             </List>
//         </Dialog>
//     );
// }

const RfqRow = ({ rfq, isAdmin }: { rfq: any; isAdmin: boolean }) => {
    const [status, setStatus] = React.useState(rfq.status);
    const { t } = useTranslation();
    const history = useHistory();

    const handleRfqSelect = (): void => {
        if (isAdmin) {
            history.push(`/rfq/${rfq.id}/view`);
            return;
        }
        if (rfq.status === RFQ_STATUS.QUOTE_REQUESTED) {
            history.push(`/rfq/${rfq.id}/view`);
            // } else if ([RFQ_STATUS.QUOTE_REQUEST_CONFIRMED, RFQ_STATUS.QUOTE_REQUEST_CONFIRMED].includes(rfq.status)) {
        } else if (rfq.status === null || rfq.status === RFQ_STATUS.NEW) {
            history.push(`/rfq/${rfq.id}`);
        } else {
            history.push(`/rfq/${rfq.id}/view`);
        }
    };
    const handleRfqStatusChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setStatus(+(e.target.value as number));
    };
    React.useEffect(() => {
        if (status >= 0 && status !== rfq.status)
            API.graphql(graphqlOperation(updateRfq, { input: { id: rfq.id, status } }));
    }, [status]);

    return (
        <TableRow key={rfq.id} style={{ cursor: 'pointer' }}>
            <TableCell align="left" onClick={handleRfqSelect}>
                {rfq.id}
            </TableCell>
            <TableCell align="left" onClick={handleRfqSelect}>
                <Trans t={t}>Appuis néoprène</Trans>
            </TableCell>
            <TableCell align="left" onClick={handleRfqSelect}>
                {rfq.sizingType === 'VOLUMES' && <Trans t={t}>Volumes</Trans>}
                {(!!!rfq.sizingType || rfq.sizingType === 'DIMENSIONS') && <Trans t={t}>Dimensions</Trans>}
                {rfq.sizingType === 'CONSTRAINTS' && <Trans t={t}>Constraints</Trans>}
            </TableCell>
            <TableCell align="left">
                {rfq.version}
                {isAdmin && (
                    <div style={{ borderTop: '1px dashed #ccc' }}>
                        {rfq.revisions?.items?.map((revision: any) => (
                            <div key={revision.id}>
                                <a href={`/rfq/${revision.id}/view`}>{revision.version}</a>
                            </div>
                        ))}
                    </div>
                )}
            </TableCell>
            <TableCell align="left">{formatDate(rfq.createdAt, 'L LT')}</TableCell>
            <TableCell align="left">
                {<RFQStatus rfq={rfq} />}
                {isAdmin && (
                    <FormControl style={{ marginTop: 15, marginBottom: 15 }}>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select labelId="status-label" id="status" value={status} onChange={handleRfqStatusChange}>
                            <MenuItem value={0}>Chiffrage initié</MenuItem>
                            <MenuItem value={1}>Chiffrage initié quote demandée</MenuItem>
                            <MenuItem value={2}>Chiffrage en cours</MenuItem>
                            <MenuItem value={3}>Chiffrage reçu</MenuItem>
                            <MenuItem value={4}>Commande passée</MenuItem>
                            <MenuItem value={5}>Job terminé</MenuItem>
                            <MenuItem value={6}>Chiffrage abandonné</MenuItem>
                        </Select>
                    </FormControl>
                )}
            </TableCell>
        </TableRow>
    );
};
