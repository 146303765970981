import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createProject, updateProject, createProjectMember } from '../../../../graphql/mutations';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import { useForm, Controller } from 'react-hook-form';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import { Trans, useTranslation } from 'react-i18next';

// import { ProductFamilyPicker } from '../../../../components/ProductFamilyPicker';
import { GetProjectQuery, ListUsersQuery } from '../../../../API';
import { getProject, listUsers } from '../../../../graphql/queries';
import CountrySelect from '../../../../components/CountrySelect';
import { getUUID } from '../../../../helpers';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { USER_ROLES } from '../../../../constants';
import { createRFQ as apiCreateRFQ } from 'src/domains/marketplace/api/projects';

import { createUser, createChannelMember, updateUser, createChatUser } from '../../../../graphql/mutations';
import { createChatChannel, createChannel } from '../../../../graphql/mutations';
import { CreateChatChannelMutation, CreateChatUserMutation } from '../../../../API';
import useProjects from '../../../../hooks/useProjects';
import { DocumentFolder } from '../../../../core/components/DocumentFolder/DocumentFolder';
import { ENABLE_RC } from 'src/domains/marketplace/api/users';
import { Typography } from '@material-ui/core';
// import { createChatUserRemote } from '../../api/users';
import Breadcrumbs from 'src/core/components/Breadcrumbs/Breadcrumbs';
import ProjectName from '../ProjectName';

const StyledForm = styled.form`
    max-width: 600px;

    fieldset {
        /* margin-bottom: 30px; */
        legend + div {
            margin-top: 12px;
        }
        /* margin-bottom: 16px; */
    }
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // '& .MuiTextField-root': {
            //     margin: theme.spacing(1),
            //     width: '200',
            // },
        },
    }),
);

const createChatChannelRemote = async (user: any, projectId: string, members: any[]) => {
    // const chatUserId = user.chatUserId;

    const response = (await API.graphql(
        graphqlOperation(createChatChannel, { users: members.map((member) => member.chat.userId) }),
    )) as {
        data: CreateChatChannelMutation;
    };
    if (response.data.createChatChannel) {
        console.log(JSON.parse(response.data.createChatChannel));
        const data = JSON.parse(response.data.createChatChannel);
        const channelId = getUUID();

        const newChannel = {
            id: channelId,
            roomId: data.body.channel.id,
            name: data.body.channel.name,
            projectId,
        };
        const channel = (await API.graphql(graphqlOperation(createChannel, { input: newChannel }))) as {
            data: CreateChatChannelMutation;
        };
        console.log(channel);

        Promise.all(
            members.map(async (member) => {
                const newChannelMember = {
                    channelId,
                    userId: member.id,
                    projectId,
                };
                await API.graphql(graphqlOperation(createChannelMember, { input: newChannelMember }));
            }),
        );
    }
};

type FormInputs = {
    id: string;
    name: string;
    productFamilies: Array<string | null>;
    shippingAddress: string;
    structureType: string;
    targetDeliveryDate: string;
    incoterm: string;
    country: string;
    pitch: string;
    prettyName?: string;
    isUrgentDelivery: boolean;
};
type Props = {
    onSubmit: (data: any) => void;
    isEdit?: boolean;
    submitLabel: string;
};

// type Project = Omit<Exclude<GetProjectQuery["getProject"], null>, "__typename">;

export const ProjectForm: React.FC<Props> = (props) => {
    const classes = useStyles();
    const { projectId } = useParams<{ projectId: string }>();
    const { setCurrentProjectId, setProject } = useProjects();
    const [data, setData] = React.useState<any>({});
    const [editable, setEditable] = React.useState(!props.isEdit);
    const { t } = useTranslation();

    const { handleSubmit, control, reset } = useForm<FormInputs>({
        defaultValues: {
            name: '',
            pitch: '',
            country: '',
            incoterm: '',
            shippingAddress: '',
            targetDeliveryDate: '',
            productFamilies: [],
            isUrgentDelivery: false,
        },
    });
    const onSubmit = (data: any) => {
        console.log('onSubmit', data);
        props.onSubmit(data);
    };

    async function fetchProject() {
        const response = (await API.graphql(graphqlOperation(getProject, { id: projectId }))) as {
            data: GetProjectQuery;
        };
        const project = response.data.getProject as any;
        setProject(project);
        return project;
    }

    React.useEffect(() => {
        // console.log(id);
        if (props.isEdit) {
            (async () => {
                setData(await fetchProject());
            })();
        }
    }, [setData]);

    React.useEffect(() => {
        if (projectId) setCurrentProjectId(projectId);
    }, [projectId]);

    React.useEffect(() => {
        reset({
            name: data.name,
            pitch: data.pitch,
            country: data.country,
            incoterm: data.incoterm,
            shippingAddress: data.shippingAddress,
            targetDeliveryDate: data.targetDeliveryDate,
            productFamilies: data.productFamilies,
            isUrgentDelivery: data.isUrgentDelivery,
        });
    }, [data]);

    return (
        <div className={classes.root} style={{ marginTop: 20 }}>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={5}>
                    {/* 
                    <Grid item xs={12}>
                        <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">Structure Type</FormLabel>

                            <Controller
                                as={
                                    <Select>
                                        <MenuItem value={'BRIDGE'}>Bridge</MenuItem>
                                        <MenuItem value={'ROAD'}>Road</MenuItem>
                                    </Select>
                                }
                                name="structureType"
                                control={control}
                            />
                        </FormControl>
                    </Grid> */}
                    <Grid item xs={12}>
                        {!editable && (
                            <Button variant="outlined" color="primary" onClick={() => setEditable(true)} size="large">
                                <Trans t={t}>Edit Job</Trans>
                            </Button>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl component="fieldset" style={{ width: '100%' }} disabled={!editable}>
                            {/* <FormLabel component="legend">
                                <Trans t={t}>Job Name</Trans>
                            </FormLabel> */}

                            <Controller
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        required
                                        label={t('Job Name')}
                                        disabled={!editable}
                                        variant="standard"
                                    />
                                )}
                                name="name"
                                control={control}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl component="fieldset" style={{ width: '100%' }} required disabled={!editable}>
                            <FormLabel component="legend">{t('Country')}</FormLabel>

                            <Controller
                                render={({ field }) => <CountrySelect {...field} disabled={!editable} />}
                                name="country"
                                control={control}
                                rules={{ required: true }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl component="fieldset" style={{ width: '100%' }} disabled={!editable}>
                            {/* <FormLabel component="legend">Shipping Address</FormLabel> */}

                            <Controller
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        disabled={!editable}
                                        fullWidth
                                        required
                                        label={t('Shipping Address')}
                                        variant="standard"
                                        helperText={t(
                                            "si votre chantier n'est pas en France, un devis spécifique sera réalisé",
                                        )}
                                    />
                                )}
                                name="shippingAddress"
                                control={control}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl
                            component="fieldset"
                            variant="standard"
                            required
                            style={{ minWidth: 200 }}
                            disabled={!editable}
                        >
                            <FormLabel component="legend">{t('Incoterm')}</FormLabel>
                            <Controller
                                render={({ field }) => (
                                    <Select {...field} disabled={!editable}>
                                        <MenuItem value={'EXW'}>EXW</MenuItem>
                                        <MenuItem value={'CIF'}>CIF</MenuItem>
                                        <MenuItem value={'DDP'}>{t('DDP hors TVA (pour la France)')}</MenuItem>
                                    </Select>
                                )}
                                rules={{ required: true }}
                                name="incoterm"
                                control={control}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl
                            component="fieldset"
                            variant="standard"
                            style={{ width: '100%' }}
                            disabled={!editable}
                        >
                            {/* <FormLabel component="legend">Estimated date of delivery :</FormLabel> */}
                            <Controller
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        disabled={!editable}
                                        required
                                        label={t('Estimated date of delivery')}
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            min: new Date().toISOString().split('T')[0],
                                        }}
                                    />
                                )}
                                name="targetDeliveryDate"
                                control={control}
                            />
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <FormControl component="fieldset" disabled={!editable}>
                            <FormLabel component="legend">
                                <Trans t={t}>Urgent delivery</Trans>
                            </FormLabel>
                            <Controller
                                render={({ field }) => (
                                    <Switch {...field} checked={field.value === true} disabled={!editable} />
                                )}
                                name="isUrgentDelivery"
                                control={control}
                            />
                        </FormControl>
                    </Grid> */}

                    {/*<Grid item xs={12}>
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                            <FormLabel component="legend">Details</FormLabel>

                            <Controller
                                as={<TextField fullWidth multiline label="Details" variant="standard" />}
                                name="pitch"
                                control={control}
                            />
                        </FormControl>
                    </Grid> */}

                    {/* <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Type of products</FormLabel>

                            <Controller
                                render={({ field }) => <ProductFamilyPicker {...field} />}
                                name="productFamilies"
                                control={control}
                            />
                        </FormControl>
                    </Grid> */}

                    <Grid item xs={12}>
                        {projectId && (
                            <DocumentFolder
                                uri={`projects/${projectId}/brief`}
                                label={t('Brief documents')}
                                projectId={projectId}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit" size="large" disabled={!editable}>
                            {props.submitLabel}
                        </Button>
                    </Grid>
                </Grid>
            </StyledForm>
        </div>
    );
};

type EditFormProps = {
    //   id: string;
};
export const ProjectEditForm: React.FC<EditFormProps> = (props) => {
    const { projectId } = useParams<{ projectId: string }>();
    const history = useHistory();
    const { t } = useTranslation();
    const [project, setProject] = React.useState<any | undefined>();

    async function fetchProject() {
        const response = (await API.graphql(graphqlOperation(getProject, { id: projectId }))) as {
            data: GetProjectQuery;
        };
        const project = response.data.getProject as any;
        return project;
    }

    async function handleSubmit(data: any) {
        try {
            // if (!formState.name) return;
            const project = { id: projectId, ...data };
            await API.graphql(graphqlOperation(updateProject, { input: project }));
            history.push('/');
        } catch (err) {
            console.log('error updating project:', err, { ...data });
        }
    }

    React.useEffect(() => {
        (async () => {
            setProject(await fetchProject());
        })();
    }, [setProject]);

    return (
        <div>
            {project && (
                <>
                    <Breadcrumbs crumbs={[{ name: <ProjectName project={project} />, path: `./` }]} />
                    <Typography variant="h4">
                        <Trans t={t}>Job details #</Trans> {projectId}
                    </Typography>
                    <Typography paragraph>
                        <Trans t={t}>Describe your Job to help our team understand better your requirements</Trans>
                    </Typography>
                    <ProjectForm onSubmit={handleSubmit} isEdit={true} submitLabel={t('Save changes')} />
                </>
            )}
        </div>
    );
};

export const ProjectAddForm = () => {
    const { currentUser } = useCurrentUser();
    const history = useHistory();
    const { t } = useTranslation();

    const createRFQForProject = React.useCallback(
        async (projectId: string) => {
            const rfq = await apiCreateRFQ(currentUser, projectId);
            history.push(`/rfq/${rfq.id}`);
        },
        [currentUser],
    );

    async function handleSubmit(data: any) {
        try {
            // if (!formState.name) return;

            // const haikunator = new Haikunator();
            // const prettyName = haikunator.haikunate();

            const projectId = getUUID();
            const project = { id: projectId, creatorId: currentUser.id, ...data };
            const projectMemberBuyer = {
                projectId,
                userId: currentUser.id,
                role: USER_ROLES.BUYER,
            };

            await API.graphql(graphqlOperation(createProject, { input: project }));
            await API.graphql(graphqlOperation(createProjectMember, { input: projectMemberBuyer }));

            const broker = (await API.graphql(
                graphqlOperation(listUsers, {
                    filter: {
                        role: { eq: USER_ROLES.BROKER },
                    },
                }),
            )) as {
                data: ListUsersQuery;
            };
            const members = [currentUser];
            if (broker && broker?.data?.listUsers?.items && broker?.data?.listUsers?.items!.length > 0) {
                const projectMemberBroker = {
                    projectId: project.id,
                    userId: broker!.data!.listUsers!.items[0]!.id,
                    role: USER_ROLES.BROKER,
                };
                await API.graphql(graphqlOperation(createProjectMember, { input: projectMemberBroker }));
                members.push(broker!.data!.listUsers!.items[0]);
            }

            // await createChatUserRemote(user.id, authUser);
            // disabled for v0
            if (ENABLE_RC) {
                await createChatChannelRemote(currentUser, projectId, members);
            }
            // history.push(`/`);
            await createRFQForProject(projectId);
        } catch (err) {
            console.log('error creating project:', err);
        }
    }

    return (
        <div>
            <Typography variant="h4">
                <Trans t={t}>Create a new Job</Trans>
            </Typography>
            <Typography paragraph>
                <Trans t={t}>Describe your Job to help our team understand better your requirements</Trans>
            </Typography>
            <ProjectForm onSubmit={handleSubmit} isEdit={false} submitLabel={t('Submit and create an RFQ')} />
        </div>
    );
};
