import React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

import { CATALOG } from './catalog';
import { AttributeList } from './types';

type Props = {
    uri: string;
    children?: React.ReactNode;
};

const StyledProductSchema = styled.img`
    /* border-radius: 5px; */
    /* border: 10px solid #fff; */
    box-sizing: border-box;
    height: 200px;
`;

export const ProductPreview = (props: Props) => {
    const productDef = CATALOG.find((x) => x.uri === props.uri);
    const attrWithHints = (productDef?.attributes as AttributeList).filter((x: any) => !!x.hint && !!!x.calc);

    return (
        <Grid container style={{ marginTop: 16, marginBottom: 16 }}>
            {props.children && (
                <Grid item xs={12}>
                    {props.children}
                </Grid>
            )}
            <Grid item xs={4}>
                <StyledProductSchema src={`/images/products/${props.uri}.png`} />
            </Grid>
            {attrWithHints && attrWithHints?.length > 0 && (
                <Grid item xs={4}>
                    <ul style={{ listStyle: 'none', fontSize: 12, color: '#999' }}>
                        {attrWithHints.map((attr: any) => (
                            <li key={attr.uri}>
                                {attr.label}: {attr.hint} {attr.unit && <span>({attr.unit})</span>}
                            </li>
                        ))}
                    </ul>
                </Grid>
            )}
            <Grid item xs={4}>
                {productDef?.description.split('\n').map((item: string, idx: number) => {
                    return (
                        <React.Fragment key={idx}>
                            {item}
                            <br />
                        </React.Fragment>
                    );
                })}
            </Grid>
        </Grid>
    );
};
