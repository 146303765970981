import { API, graphqlOperation } from 'aws-amplify';
import Haikunator from 'haikunator';

import { createUser, createChannelMember, updateUser, createChatUser, updateChatUser } from 'src/graphql/mutations';
import { createChatChannel, createChannel } from 'src/graphql/mutations';
import { CreateChatChannelMutation, CreateChatUserMutation, UpdateChatUserMutation } from 'src/API';
// import { USER_STATUS, USER_ROLES } from 'src/constants';
import { getUUID } from 'src/helpers';
import { CreateUserMutation, UpdateUserMutation } from 'src/API';

export const ENABLE_RC = false;

export const createChatUserRemote = async (userId: string, authUser: any): Promise<any> => {
    const haikunator = new Haikunator();
    const userName = haikunator.haikunate();
    const response = (await API.graphql(
        graphqlOperation(createChatUser, { userId, name: userName, email: authUser.attributes.email }),
    )) as {
        data: CreateChatUserMutation;
    };
    if (response.data.createChatUser) {
        const chatUser = JSON.parse(response.data.createChatUser);
        return chatUser.body;
    }
};
const createChatChannelRemote = async (chatUserIds: [string]) => {
    const response = (await API.graphql(graphqlOperation(createChatChannel, { users: chatUserIds }))) as {
        data: CreateChatChannelMutation;
    };
    if (response.data.createChatChannel) {
        console.log(JSON.parse(response.data.createChatChannel));
        const data = JSON.parse(response.data.createChatChannel);
        return data.body;
    }
};

export const createMarketplaceUser = async (authUser: any) => {
    const userId = getUUID();

    const directChannelId = getUUID();
    let chat = {};

    if (ENABLE_RC) {
        const chatUser = await createChatUserRemote(userId, authUser);
        const chatUserId = chatUser.user._id;
        const chatDirectChannel = await createChatChannelRemote([chatUserId]);
        const newChannel = {
            id: directChannelId,
            roomId: chatDirectChannel.channel.id,
            name: chatDirectChannel.channel.name,
        };
        const channel = (await API.graphql(graphqlOperation(createChannel, { input: newChannel }))) as {
            data: CreateChatChannelMutation;
        };
        console.log('Created remote channel', channel);

        chat = {
            userId: chatUserId,
            loginToken: chatUser.authToken,
        };
    }

    // const directChannelId = 'fake';
    // const chatUserId = 'fake';
    // const chatUser = { authToken: 'fake' };

    const newUser = {
        id: userId,
        email: authUser.attributes.email,
        authUserId: authUser.username, // cognito id
        directChannelId,
        chat,
    };
    const newUserReponse = (await API.graphql(graphqlOperation(createUser, { input: newUser }))) as {
        data: CreateUserMutation;
    };
    console.log('newUserReponse', newUserReponse);

    // const newChannelMember = {
    //     channelId: directChannelId,
    //     userId: userId,
    // };
    // await API.graphql(graphqlOperation(createChannelMember, { input: newChannelMember }));

    return newUserReponse.data.createUser;
};

export const updateMarketplaceUser = async (userData: any, currentUser: any, authUser: any) => {
    const userId = currentUser.id;
    const updatedUser = {
        ...userData,
        id: userId,
    };
    const response = (await API.graphql(graphqlOperation(updateUser, { input: updatedUser }))) as {
        data: UpdateUserMutation;
    };

    // fullname change ?
    // disabled without chat server
    if (userData.fullName && ENABLE_RC) {
        const response = (await API.graphql(
            graphqlOperation(updateChatUser, {
                userId,
                name: userData.fullName,
                email: authUser.attributes.email,
            }),
        )) as {
            data: UpdateChatUserMutation;
        };
    }

    return response.data.updateUser;
};

// export default createUser;
