import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import useCurrentUser from 'src/hooks/useCurrentUser';
import { fetchProjects } from 'src/domains/marketplace/api/projects';

type ProjectSelectProps = {
    onChange: (value: string) => void;
    value?: string;
};

const ProjectSelect = ({ onChange, value }: ProjectSelectProps) => {
    const { currentUser } = useCurrentUser();

    const [projects, setProjects] = React.useState<any | undefined>(undefined);
    const [project, setProject] = React.useState(value || '');
    // const classes = useStyles();

    async function loadProjects() {
        setProjects(await fetchProjects(currentUser));
    }

    React.useEffect(() => {
        loadProjects();
    }, []);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setProject(event.target.value as string);
        if (onChange) {
            onChange(event.target.value as string);
        }
    };

    return (
        <FormControl style={{ minWidth: 200 }}>
            <InputLabel id="simple-select-label">Jobs</InputLabel>
            <Select labelId="simple-select-label" id="simple-select" value={project} onChange={handleChange}>
                {projects &&
                    projects.map((project: any) => (
                        <MenuItem key={project.id} value={project.id}>
                            {project.name}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

export default ProjectSelect;
