import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import { I18n } from 'aws-amplify';
import AmplifyI18n from 'amplify-i18n';

import awsExports from './aws-exports';
import './i18n';

// hack to remove oauth config showing sign in with AWS
Amplify.configure((({ oauth, ...o }) => o)(awsExports));

// const locales = ['en', 'fr'];
AmplifyI18n.configure(['en', 'fr']);
I18n.setLanguage('fr');

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback="loading">
            <App />
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
