export type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export type CognitoUser = any;

export enum ProductFamily {
    SILO,
    ELASTOMERIC_BEARING,
    POST_TENSIONING_BAR,
}
export enum Role {
    BUYER = 'BUYER',
    VENDOR = 'VENDOR',
    BROKER = 'BROKER',
}

export enum SizingType {
    DIMENSIONS = 'DIMENSIONS',
    VOLUMES = 'VOLUMES',
    CONSTRAINTS = 'CONSTRAINTS',
}

export enum StructureType {
    BRIDGE,
    ROAD,
}

export type Activity = {
    id: string;
    verb: number;
    target?: string;
    object: string;
    actor: string;
    params?: any;
};
