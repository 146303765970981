import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { formatDate } from '../../../../helpers';
import Chip from '@material-ui/core/Chip';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// import useProjects from '../../../../hooks/useProjects';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { fetchProjects } from 'src/domains/marketplace/api/projects';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

type ProductFamilyListProps = {
    items?: (string | null)[] | null;
};
const ProductFamilyList = (props: ProductFamilyListProps) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: 'flex',
                justifyContent: 'left',
                flexWrap: 'wrap',
                '& > *': {
                    margin: theme.spacing(0.5),
                },
            },
        }),
    );
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {props?.items?.map((item, idx) => {
                return <Chip key={idx} label={item} />;
            })}
        </div>
    );
};
export const ProjectList = () => {
    // const { projects } = useProjects();
    const { currentUser } = useCurrentUser();

    const [projects, setProjects] = React.useState<any | undefined>(undefined);
    const classes = useStyles();
    const history = useHistory();

    async function loadProjects() {
        setProjects(await fetchProjects(currentUser));
    }

    React.useEffect(() => {
        loadProjects();
    }, []);

    const handleProjectSelect = (projectId: string): void => {
        history.push(`/job/${projectId}`);
    };

    return (
        <div>
            <h1>Jobs</h1>

            {projects && projects.length === 0 && <div>Create your first job</div>}
            <TableContainer>
                <Table className={classes.table} aria-label="jobs list" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell align="left">Country</TableCell>
                            <TableCell align="left">Products</TableCell>
                            <TableCell align="left">Creation date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projects?.map(
                            (project: any) =>
                                project && (
                                    <TableRow key={project.id} onClick={(e) => handleProjectSelect(project.id)}>
                                        <TableCell component="th" scope="row">
                                            {project.id}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {project.name}
                                        </TableCell>
                                        <TableCell align="left">{project.country}</TableCell>
                                        <TableCell align="left">
                                            <ProductFamilyList items={project.productFamilies} />
                                        </TableCell>
                                        <TableCell align="left">{formatDate(project.createdAt, 'LL')}</TableCell>
                                    </TableRow>
                                ),
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <br />
            <Button variant="contained" color="primary" component={RouterLink} to="/jobs/new">
                Create a new Job
            </Button>
        </div>
    );
};
