import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import DocumentFolderItem from './DocumentFolderItem';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            // maxWidth: 360,
            // backgroundColor: theme.palette.background.paper,
        },
    }),
);

type Props = {
    items: any[];
    onDeleteDocument: (doc: any) => void;
};
export default function DocumentFolderItems({ items, onDeleteDocument }: Props) {
    const classes = useStyles();

    return (
        <List className={classes.root}>
            {items.map((item) => (
                <DocumentFolderItem key={item.id} item={item} onDeleteDocument={onDeleteDocument} />
            ))}
        </List>
    );
}
