/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:78ee083c-6891-4f05-adb5-92d40ba8f118",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_KNSFZYtMt",
    "aws_user_pools_web_client_id": "73817rog34hrt16d4m9v27qvkr",
    "oauth": {
        "domain": "pontenovu17c0bf4a-17c0bf4a-devnew.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3002/",
        "redirectSignOut": "http://localhost:3002/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://splaidtszfcjhhpbon3t62buna.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "pontenovu1b5a27d536aa46da99f9810d84a0493a145624-devnew",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
