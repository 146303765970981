import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import TopAppBar from 'src/domains/marketplace/components/Navigation/AppBar';
import { ProjectList } from '../domains/marketplace/components/Project/ProjectList';
import { ProjectEditForm, ProjectAddForm } from '../domains/marketplace/components/Project/ProjectForm';
import { ChannelWebview } from './ChannelWebview';
import useCurrentUser from '../hooks/useCurrentUser';
import { RocketChatIframe } from './RocketChatIframe';
import Profile from 'src/domains/marketplace/components/Profile/Profile';
import { Welcome } from 'src/domains/marketplace/components/Onboarding/Welcome';
import { BuyerDashboard } from 'src/domains/marketplace/components/BuyerDashboard/BuyerDashboard';
import RFQCreate from 'src/domains/marketplace/components/RFQ/RFQCreate';

import { RFQForm } from 'src/domains/marketplace/components/InstantQuote/RFQForm';
import InstantQuote from 'src/domains/marketplace/components/InstantQuote/InstantQuote';
import SideInbox from 'src/domains/marketplace/components/Inbox/SideInbox';
import { RFQExecution } from 'src/domains/marketplace/components/RFQ/RFQExecution';
// import RFQIndex from 'src/domains/marketplace/components/RFQ/RFQIndex';
// import { useQuery } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            padding: theme.spacing(1),
            paddingTop: theme.spacing(3),
            // marginTop: '-64px',
            // paddingLeft: theme.spacing(10),
            // paddingRight: '300px',
        },
    }),
);

export const CustomerApp = () => {
    const history = useHistory();
    const { currentUser, authUser } = useCurrentUser();
    const classes = useStyles();

    React.useEffect(() => {
        if (currentUser === null) history.push('/onboarding');
    }, [currentUser]);

    return currentUser === undefined ? (
        <div></div>
    ) : (
        <>
            <TopAppBar />
            {currentUser && <SideInbox />}
            <Container className={classes.content}>
                {/* {currentUser === null && (
                    <Route exact path="/onboarding">
                        <Welcome />
                    </Route>
                )} */}
                {authUser && (
                    <>
                        <Route exact path="/onboarding">
                            <Welcome />
                        </Route>
                        <Route path="/" exact>
                            <BuyerDashboard />
                        </Route>
                        {/* will return rc login token with postMessage between frames */}
                        {/* <Route path="/rocketchat_iframe" exact>
                            <RocketChatIframe />
                        </Route> */}
                        <Route exact path="/jobs">
                            <ProjectList />
                        </Route>
                        <Route exact path="/rfqs/new">
                            <RFQCreate />
                        </Route>
                        <Route exact path="/rfq/:rfqId">
                            <RFQForm />
                        </Route>
                        <Route exact path="/rfq/:rfqId/overview">
                            <RFQExecution />
                        </Route>
                        <Route exact path="/rfq/:rfqId/view">
                            <InstantQuote />
                        </Route>
                        <Route exact path="/profile">
                            <Profile />
                        </Route>
                        <Route path="/jobs/new">
                            <ProjectAddForm />
                        </Route>
                        <Route exact path="/job/:projectId">
                            <ProjectEditForm />
                        </Route>

                        {/* <Route exact path="/rfqs/:rfqId">
                    <RFQIndex />
                </Route> */}
                        {/* <Route exact path="/rfqs/:rfqId/:collaboratorId">
                    <RFQIndex />
                </Route> */}

                        {/* <Route exact path="/account">
                <Account />
            </Route> */}

                        {/* <Route path="/jobs/:projectId/settings">
                    <ProjectEditForm />
                </Route> */}

                        {/* <Route path="/jobs/:projectId/rfqlist">
                    <RFQList />
                </Route> */}
                        {/* <Route path="/jobs/:projectId/rfq/new">
                    <RFQForm />
                </Route>
                <Route path="/jobs/:projectId/rfqs/:rfqId">
                    <RFQForm />
                </Route> */}
                        {/* <Route path="/jobs/:projectId/docs/">
                    <DocumentList />
                </Route> */}
                        {/* <Route path="/channels/:channelId">
                            <ChannelWebview />
                        </Route> */}
                    </>
                )}
            </Container>
        </>
    );
};
