import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import styled from 'styled-components';
// import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { Trans, useTranslation } from 'react-i18next';

// import { ChatIcon } from '@heroicons/react/solid';
import { DocumentFolder } from 'src/core/components/DocumentFolder/DocumentFolder';
import useProjects from 'src/hooks/useProjects';
import { getRfq } from '../../../../graphql/queries';
// import * as Styled from './styles';
import { GetRfqQuery } from '../../../../API';
// import { updateRfq } from '../../../../graphql/mutations';
// import { formatDate } from '../../../../helpers';
import { RFQ_STATUS } from 'src/constants';
import { SizingType } from 'src/types';
import ProjectName from '../ProjectName';
import Breadcrumbs from 'src/core/components/Breadcrumbs/Breadcrumbs';
import { Typography } from '@material-ui/core';
import { RFQStatus } from '../RFQ/RFQStatus';
import { formatDate } from 'src/helpers';
import { updateRfq } from 'src/graphql/mutations';
import ProjectInbox, { ProjectChat } from '../Inbox/ProjectInbox';

export const RFQExecution = () => {
    const { rfqId } = useParams<{ rfqId: string }>();
    const history = useHistory();
    const [rfq, setRfq] = React.useState<any>(undefined);
    // const classes = useStyles();
    const { t } = useTranslation();
    const { setProject } = useProjects();

    async function fetchRfq() {
        const response = (await API.graphql(graphqlOperation(getRfq, { id: rfqId }))) as {
            data: GetRfqQuery;
        };
        setRfq(response.data.getRFQ);
    }
    React.useEffect(() => {
        fetchRfq();
        //hack to hide side inbox
        setProject(null);
    }, []);

    return (
        <>
            {rfq && (
                <Breadcrumbs
                    crumbs={[
                        { name: <ProjectName project={rfq.project} />, path: `/job/${rfq.project.id}` },
                        { name: `${t('RFQ')} #${rfq.id}`, path: `/rfq/${rfq.id}` },
                        { name: `${t('Overview')}`, path: './' },
                    ]}
                />
            )}

            {rfq && (
                <>
                    <header style={{ background: '#efefef', borderRadius: '10px', padding: '15px', marginBottom: 30 }}>
                        <Typography variant="h4">
                            <Trans t={t}>Overview</Trans>
                        </Typography>
                        <Typography paragraph>
                            {rfq.project.targetDeliveryDate && (
                                <>
                                    <Trans t={t}>Estimated date of delivery</Trans>:{' '}
                                    {formatDate(rfq.project.targetDeliveryDate, 'LL')} <br />
                                </>
                            )}
                            {rfq.project.incoterm && (
                                <>
                                    <Trans t={t}>Incoterm</Trans>: {rfq.project.incoterm}
                                    <br />
                                </>
                            )}
                            <Trans t={t}>Sizing type</Trans>:{' '}
                            {rfq.sizingType === SizingType.VOLUMES && <Trans t={t}>Volumes</Trans>}
                            {(!!!rfq.sizingType || rfq.sizingType === SizingType.DIMENSIONS) && (
                                <Trans t={t}>Dimensions</Trans>
                            )}
                            {rfq.sizingType === SizingType.CONSTRAINTS && <Trans t={t}>Constraints</Trans>}
                            <br />
                            <Trans t={t}>Status</Trans>: <RFQStatus rfq={rfq} />
                        </Typography>
                    </header>

                    <StyledGrid container>
                        <Grid item xs={6}>
                            <DocumentFolder
                                uri={`projects/${rfq.project.id}/files`}
                                label={t('Shared Documents')}
                                projectId={rfq.project.id}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ borderLeft: '1px solid #ccc' }}>
                            <h4 style={{ marginLeft: 30, fontSize: 16, fontWeight: 400, marginTop: 5 }}>
                                Conversations
                            </h4>
                            <ProjectChat project={rfq.project} />
                        </Grid>
                    </StyledGrid>
                </>
            )}
        </>
    );
};

const StyledGrid = styled(Grid)`
    .docfolder-container {
        border: 0;
    }
    .chat > div {
        height: 400px;
    }
`;
