import React, { useState } from 'react';
// import { GetProjectQuery } from '../API';

// type Project = GetProjectQuery['getProject'] | null | undefined;

// const defaultValue: ContextValue = {
//     // setProjects: useState<Array<any> | null | undefined>(undefined),
//     // setProject: useState<any | undefined>(undefined),
//     setProjects: (projects: Array<any> | null | undefined) => {
//         return projects;
//     },
//     setProject: (project: any | null | undefined) => {
//         return project;
//     },
// };
type ContextValue = {
    projects: Array<any> | undefined | null;
    project: any | null;
    setProjects: (projects: Array<any> | null | undefined) => void;
    setProject: React.Dispatch<React.SetStateAction<any>>;
    currentProjectId: string | undefined | null;
    setCurrentProjectId: React.Dispatch<React.SetStateAction<any>>;
};
const ProjectsContext = React.createContext<ContextValue>({} as ContextValue);
// {} as ContextValue
// type Project = Omit<Exclude<GetProjectQuery['getProject'], null>, '__typename'> | undefined;

// const defaultProjects: Array<Project> = undefined;
type Props = {
    children: React.ReactNode;
};
export const ProjectsProvider = (props: Props) => {
    const [projects, setProjects] = useState<Array<any> | null | undefined>(undefined);
    const [project, setProject] = useState<any | undefined>(undefined);
    const [currentProjectId, setCurrentProjectId] = useState<string | null | undefined>(undefined);

    // const saveProjects = (values) => {
    //     setCurrentProjects(values);
    // };
    React.useEffect(() => {
        if (currentProjectId && projects) {
            setProject(projects.find((x) => x.id === currentProjectId));
        }
    }, [currentProjectId, projects]);

    return (
        <ProjectsContext.Provider
            value={{ projects, setProjects, project, setProject, currentProjectId, setCurrentProjectId }}
        >
            {props.children}
        </ProjectsContext.Provider>
    );
};

export const ProjectsConsumer = ProjectsContext.Consumer;

export default ProjectsContext;
