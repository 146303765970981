/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      owner
      username
      fullName
      firstName
      lastName
      mobilePhone
      role
      status
      company {
        name
        address
        country
        website
        phone
        duns
        siret
        countries
        productFamilies
      }
      jobTitle
      authUserId
      chat {
        userId
        loginToken
      }
      directChannelId
      directChannel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      activityFeeds {
        items {
          id
          userId
          activityFeedId
          feed {
            id
            uri
            activities {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      pitch
      status
      prettyName
      productFamilies
      country
      incoterm
      shippingAddress
      structureType
      targetDeliveryDate
      members {
        items {
          id
          projectId
          userId
          role
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      channels {
        items {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rfqs {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isUrgentDelivery
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProjectMember = /* GraphQL */ `
  query GetProjectMember($id: ID!) {
    getProjectMember(id: $id) {
      id
      projectId
      userId
      role
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProjectMembers = /* GraphQL */ `
  query ListProjectMembers(
    $filter: ModelProjectMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectId
        userId
        role
        user {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        project {
          id
          name
          pitch
          status
          prettyName
          productFamilies
          country
          incoterm
          shippingAddress
          structureType
          targetDeliveryDate
          members {
            items {
              id
              projectId
              userId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          channels {
            items {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            nextToken
          }
          rfqs {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isUrgentDelivery
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProjectMessage = /* GraphQL */ `
  query GetProjectMessage($id: ID!) {
    getProjectMessage(id: $id) {
      id
      projectId
      userId
      content
      author {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProjectMessages = /* GraphQL */ `
  query ListProjectMessages(
    $filter: ModelProjectMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectId
        userId
        content
        author {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      name
      size
      contentType
      S3Key
      projectId
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      folderId
      folderUri
      createdAt
      updatedAt
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        size
        contentType
        S3Key
        projectId
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        folderId
        folderUri
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocumentFolder = /* GraphQL */ `
  query GetDocumentFolder($id: ID!) {
    getDocumentFolder(id: $id) {
      id
      uri
      countDocuments
      documents {
        items {
          id
          name
          size
          contentType
          S3Key
          projectId
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          folderId
          folderUri
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDocumentFolders = /* GraphQL */ `
  query ListDocumentFolders(
    $filter: ModelDocumentFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uri
        countDocuments
        documents {
          items {
            id
            name
            size
            contentType
            S3Key
            projectId
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            folderId
            folderUri
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRfq = /* GraphQL */ `
  query GetRfq($id: ID!) {
    getRFQ(id: $id) {
      id
      name
      projectId
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      productFamily
      description
      version
      parentId
      values
      status
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      projectMemberIds
      sizingType
      revisions {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isRevision
      createdAt
      updatedAt
    }
  }
`;
export const listRfQs = /* GraphQL */ `
  query ListRfQs(
    $filter: ModelRFQFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRFQs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        projectId
        project {
          id
          name
          pitch
          status
          prettyName
          productFamilies
          country
          incoterm
          shippingAddress
          structureType
          targetDeliveryDate
          members {
            items {
              id
              projectId
              userId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          channels {
            items {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            nextToken
          }
          rfqs {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isUrgentDelivery
          createdAt
          updatedAt
        }
        productFamily
        description
        version
        parentId
        values
        status
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        projectMemberIds
        sizingType
        revisions {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isRevision
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!) {
    getChannel(id: $id) {
      id
      name
      projectId
      roomId
      members {
        items {
          id
          channelId
          userId
          projectId
          channel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChannels = /* GraphQL */ `
  query ListChannels(
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChannelMember = /* GraphQL */ `
  query GetChannelMember($id: ID!) {
    getChannelMember(id: $id) {
      id
      channelId
      userId
      projectId
      channel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChannelMembers = /* GraphQL */ `
  query ListChannelMembers(
    $filter: ModelChannelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannelMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        channelId
        userId
        projectId
        channel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        user {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      actor
      verb
      object
      target
      params
      activityFeedId
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listActivitys = /* GraphQL */ `
  query ListActivitys(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        actor
        verb
        object
        target
        params
        activityFeedId
        user {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActivityFeed = /* GraphQL */ `
  query GetActivityFeed($id: ID!) {
    getActivityFeed(id: $id) {
      id
      uri
      activities {
        items {
          id
          actor
          verb
          object
          target
          params
          activityFeedId
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listActivityFeeds = /* GraphQL */ `
  query ListActivityFeeds(
    $filter: ModelActivityFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityFeeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uri
        activities {
          items {
            id
            actor
            verb
            object
            target
            params
            activityFeedId
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActivityFeedSubscription = /* GraphQL */ `
  query GetActivityFeedSubscription($id: ID!) {
    getActivityFeedSubscription(id: $id) {
      id
      userId
      activityFeedId
      feed {
        id
        uri
        activities {
          items {
            id
            actor
            verb
            object
            target
            params
            activityFeedId
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listActivityFeedSubscriptions = /* GraphQL */ `
  query ListActivityFeedSubscriptions(
    $filter: ModelActivityFeedSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityFeedSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        activityFeedId
        feed {
          id
          uri
          activities {
            items {
              id
              actor
              verb
              object
              target
              params
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        user {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const documentFolderByUri = /* GraphQL */ `
  query DocumentFolderByUri(
    $uri: String
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentFolderByUri(
      uri: $uri
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uri
        countDocuments
        documents {
          items {
            id
            name
            size
            contentType
            S3Key
            projectId
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            folderId
            folderUri
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
