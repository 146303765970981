import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
// import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { Trans, useTranslation } from 'react-i18next';
// import { ChatIcon } from '@heroicons/react/solid';

import { getRfq } from '../../../../graphql/queries';
// import * as Styled from './styles';
import {
    elastomericBearingCatalog,
    elastomericBearingConstraintsCatalog,
    elastomericBearingVolumesCatalog,
} from './catalog';
import { GetRfqQuery } from '../../../../API';
// import { updateRfq } from '../../../../graphql/mutations';
// import { formatDate } from '../../../../helpers';
import { SizingType } from '../../../../types';
import ProjectName from '../ProjectName';
import Breadcrumbs from 'src/core/components/Breadcrumbs/Breadcrumbs';
import { AttributeList } from './types';
import { Typography } from '@material-ui/core';
import { RFQStatus } from '../RFQ/RFQStatus';
import { formatDate } from 'src/helpers';
import { RFQ_STATUS } from 'src/constants';
import { updateRfq } from 'src/graphql/mutations';
import SideInbox from '../Inbox/SideInbox';
import useProjects from 'src/hooks/useProjects';
import useCurrentUser from 'src/hooks/useCurrentUser';

const TAX_RATE = 0.07;

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
});

// Create Document Component
const PdfQuoteDocument = () => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Text>Pontenovo Quote</Text>
            </View>
        </Page>
    </Document>
);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
        '& .MuiTableCell-root': {
            borderLeft: '1px solid rgba(224, 224, 224, .5)',
        },
    },
});

const StyledFooterCell = styled(TableCell)`
    border-top: 4px solid rgba(224, 224, 224, 0.1);
`;

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function ccyFormat(num: number) {
    return `${num.toFixed(2)}`;
}

const getProductVolume = (rfq: any, product: any) => {
    try {
        console.log('getProductVolume', product);
        if (rfq.sizingType === SizingType.VOLUMES) {
            return product.volume.value;
        } else {
            return ((product.Tb.value * product.a.value * product.b.value) / 1000000) * product.quantity.value;
        }
    } catch {
        return 0;
    }
};

const getProductUnitPriceCustom = (rfq: any, products: any[], product: any) => {
    //volume
    const totalVolume = products.reduce((total, product) => {
        return total + getProductVolume(rfq, product);
    }, 0);

    console.log('totalVolume', totalVolume);
    const BASE_PRICE = 14;
    const PRICES = [
        {
            limit: 100,
            discount: 0.07,
        },
        { limit: 1000, discount: 0.11 },
        { limit: 25000, discount: 0.14 },
    ];
    const DELIVERY = [
        { limit: 0, rate: 1 },
        { limit: 50, rate: 0.5 },
        { limit: 100, rate: 0.2 },
        { limit: 500, rate: 0.15 },
        { limit: 1000, rate: 0.1 },
    ];
    const discount = [...PRICES].reverse().find(({ limit }) => limit < totalVolume)?.discount || 0;
    const deliveryRate = [...DELIVERY].reverse().find(({ limit }) => limit < totalVolume)?.rate || 1;

    const volume = getProductVolume(rfq, product);

    const pricing = BASE_PRICE * (1 - discount);
    const deliveryFee = pricing * deliveryRate;
    const cost = volume * (pricing + deliveryFee);
    const price = cost / 0.7;
    const unitPrice = price / +product?.quantity.value;
    const result = { unitPrice, price };
    console.log('pricing:', {
        cost,
        totalVolume,
        product,
        volume,
        discount,
        deliveryRate,
        deliveryFee,
        pricing,
        result,
    });
    return result;
};

const getProductUnitPriceEXW = (rfq: any, products: any[], product: any, productUri: string) => {
    const volume = getProductVolume(rfq, product);

    // https://stackoverflow.com/questions/56833469/typescript-error-ts7053-element-implicitly-has-an-any-type
    const FACTORY_PRICES_BY_PRODUCT: { [index: string]: number } = {
        'elastomeric-bearing-a': 13,
        'elastomeric-bearing-b': 13,
        'elastomeric-bearing-c': 14.5,
        'elastomeric-bearing-d': 21,
        'elastomeric-bearing-f': 12,
    };
    const MARGIN_RATE = 1.25;

    const hasPrice = FACTORY_PRICES_BY_PRODUCT[productUri];
    if (!hasPrice) {
        return null;
    }
    const price = volume * (FACTORY_PRICES_BY_PRODUCT[productUri] as number) * MARGIN_RATE;
    // console.log('price', price);
    const unitPrice = price / +product?.quantity.value;
    const result = { unitPrice, price };
    return result;
};

const getProductUnitPrice = (rfq: any, products: any[], product: any, productUri: string) => {
    return null; // until real catalog
    if (rfq.project.incoterm === 'EXW') {
        return getProductUnitPriceEXW(rfq, products, product, productUri);
    } else if (rfq.project.incoterm === 'DDP') {
        return getProductUnitPriceCustom(rfq, products, product);
    } else {
        return null;
    }
};

const getProductCatalog = (rfq: any) => {
    switch (rfq.sizingType) {
        case 'VOLUMES':
            return elastomericBearingVolumesCatalog;
        case 'CONSTRAINTS':
            return elastomericBearingConstraintsCatalog;
        case 'DIMENSIONS':
        default:
            return elastomericBearingCatalog;
    }
};

const productToString = (rfq: any, uri: string, product: any) => {
    const catalog = getProductCatalog(rfq);
    const productDef = catalog.find((x) => x.uri === uri);
    if (!productDef) {
        return [];
    }
    const result = Object.entries(product)
        .filter(([key]) => {
            const attrDef = (productDef.attributes as AttributeList).find((x) => x.uri === key);
            return attrDef && !['quantity', 'description'].includes(attrDef.uri) && !!!attrDef.calc;
        })
        // .filter(([key, value]) => value !== true)
        .map(([key, value]) => {
            const attrDef = (productDef.attributes as AttributeList).find((x) => x.uri === key);
            // console.log(value);
            const unit = attrDef?.unit ? `${attrDef.unit}` : '';
            let valueString;
            if (attrDef?.type === 'switch') {
                valueString = (value as any).value ? 'Oui' : 'Non';
            } else {
                valueString = `${(value as any).value}${unit}`;
            }
            return `${attrDef?.label} = ${valueString}`;
        });
    // .join(', ');
    return result;
};

// const PdfQuoteDownload = () => {
//     const [ready, setReady] = React.useState(false);
//     const { t } = useTranslation();

//     // React.useEffect(() => {
//     //     // https://github.com/diegomura/react-pdf/issues/517
//     //     // https://stackoverflow.com/questions/58291789/error-stream-push-after-eof-in-react-pdf
//     //     setTimeout(() => setReady(true), 1);
//     // }, []);
//     const toggle = () => {
//         setTimeout(() => setReady(true), 1);
//     };
//     return (
//         <>
//             {ready ? (
//                 <PDFDownloadLink document={<PdfQuoteDocument />} fileName="quote.pdf">
//                     {({ blob, url, loading, error }) =>
//                         loading ? (
//                             'Loading document...'
//                         ) : (
//                             <Button color="primary" variant={'outlined'} size="large">
//                                 <Trans t={t}>Download PDF file</Trans>
//                             </Button>
//                         )
//                     }
//                 </PDFDownloadLink>
//             ) : (
//                 <Button onClick={toggle} variant={'outlined'}>
//                     <Trans t={t}>Generate PDF file</Trans>
//                 </Button>
//             )}
//         </>
//     );
// };

const InstantQuote = () => {
    const { rfqId } = useParams<{ rfqId: string }>();
    const history = useHistory();
    const [rfq, setRfq] = React.useState<any>(undefined);
    const [data, setData] = React.useState<any>(undefined);
    const [confirmed, setConfirmed] = React.useState(false);
    const rfqProducts = (rfq && JSON.parse(rfq.values)) || {};
    console.log(rfqProducts);
    const classes = useStyles();
    let totalPrice = 0;
    const { t } = useTranslation();
    const { setProject } = useProjects();
    const { isAdmin, currentUser } = useCurrentUser();
    const catalog = rfq && getProductCatalog(rfq);

    async function fetchRfq() {
        const response = (await API.graphql(graphqlOperation(getRfq, { id: rfqId }))) as {
            data: GetRfqQuery;
        };
        setRfq(response.data.getRFQ);
        setProject(response.data.getRFQ?.project);

        const catalog = getProductCatalog(response.data.getRFQ);

        if (response?.data?.getRFQ?.values) {
            const data = JSON.parse(response.data.getRFQ.values);
            setData(data);
            Object.entries(data).map(([uri, products]) => {
                (products as any[]).map((product: any, index) => {
                    const productDef = catalog.find((x: any) => x.uri === uri);
                    console.log(uri, product, productDef);
                });
            });
        }
    }
    React.useEffect(() => {
        fetchRfq();
    }, []);

    const editRfq = () => {
        history.push(`/rfq/${rfq.id}`);
    };

    const handleConfirmRequest = React.useCallback(async () => {
        if (rfq.status === RFQ_STATUS.QUOTE_REQUESTED) {
            await API.graphql(
                graphqlOperation(updateRfq, { input: { id: rfq.id, status: RFQ_STATUS.QUOTE_REQUEST_CONFIRMED } }),
            );
        }
        setConfirmed(true);
        // update local state
        fetchRfq();
        // history.push(`/rfq/${rfq.id}/overview`);
    }, [rfq]);

    const canEdit =
        rfq &&
        rfq.creator.id === currentUser.id &&
        ![RFQ_STATUS.CANCELLED, RFQ_STATUS.ORDERED, RFQ_STATUS.COMPLETED].includes(rfq.status);

    return (
        <>
            <SideInbox open={true} />
            {rfq && (
                <Breadcrumbs
                    crumbs={[
                        { name: <ProjectName project={rfq.project} />, path: `/job/${rfq.project.id}` },
                        { name: `${t('RFQ')} #${rfq.id}`, path: `/rfq/${rfq.id}` },
                        { name: `${t('Instant Quote')}`, path: './' },
                    ]}
                />
            )}
            {/* {rfq && (
                <header style={{ marginBottom: 16, display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                    <PdfQuoteDownload />
                </header>
            )} */}

            {rfq && (
                <header style={{ background: '#efefef', borderRadius: '10px', padding: '15px', marginBottom: 30 }}>
                    <Typography variant="h4">
                        <Trans t={t}>Instant Quote</Trans> #{rfq.id}-{rfq.version}
                    </Typography>
                    <Typography paragraph>
                        <div>
                            <Trans t={t}>Revision</Trans>: {rfq.version}
                        </div>
                        {rfq.project.targetDeliveryDate && (
                            <>
                                <Trans t={t}>Estimated date of delivery</Trans>:{' '}
                                {formatDate(rfq.project.targetDeliveryDate, 'LL')} <br />
                            </>
                        )}
                        {rfq.project.incoterm && (
                            <>
                                <Trans t={t}>Incoterm</Trans>: {rfq.project.incoterm}
                                <br />
                            </>
                        )}
                        <Trans t={t}>Sizing type</Trans>:{' '}
                        {rfq.sizingType === SizingType.VOLUMES && <Trans t={t}>Volumes</Trans>}
                        {(!!!rfq.sizingType || rfq.sizingType === SizingType.DIMENSIONS) && (
                            <Trans t={t}>Dimensions</Trans>
                        )}
                        {rfq.sizingType === SizingType.CONSTRAINTS && <Trans t={t}>Constraints</Trans>}
                        <br />
                        <Trans t={t}>Status</Trans>: <RFQStatus rfq={rfq} />
                    </Typography>
                </header>
            )}

            <div style={{ marginBottom: 16, display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                {canEdit && (
                    <Button variant="outlined" color="primary" onClick={editRfq} size="small">
                        <Trans t={t}>Edit specifications</Trans>
                    </Button>
                )}
            </div>

            {rfq && (
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="spanning table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <Trans t={t}>Product</Trans>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Trans t={t}>Specifications</Trans>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Trans t={t}>Quantity</Trans>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Trans t={t}>Unit Price</Trans>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Trans t={t}>Total Price</Trans>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data &&
                                Object.entries(data).map(([uri, products]) => {
                                    const productDef = catalog.find((x: any) => x.uri === uri);

                                    return (
                                        <React.Fragment key={uri}>
                                            {/* <TableRow>
                                                <TableCell>
                                                    <b>{productDef?.longName}</b>
                                                </TableCell>
                                                <TableCell>{productDef?.definition}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow> */}
                                            {(products as any[]).map((product: any, index) => {
                                                const allProducts = Object.values(data).reduce(
                                                    (acc, x) => [...(x as any[]), ...(acc as any[])],
                                                    [],
                                                );
                                                let unitPrice = 0,
                                                    price = 0;
                                                let canCalcPrice =
                                                    (rfq.sizingType === 'DIMENSIONS' && !!product.a && !!product.b) ||
                                                    (rfq.sizingType === 'VOLUMES' && !!product.volume);

                                                if (canCalcPrice) {
                                                    // with dimensions
                                                    const prices = getProductUnitPrice(
                                                        rfq,
                                                        allProducts as any[],
                                                        product,
                                                        uri,
                                                    );
                                                    if (prices === null) {
                                                        canCalcPrice = false;
                                                    } else {
                                                        price = prices.price;
                                                        unitPrice = prices.unitPrice;
                                                    }
                                                }

                                                totalPrice += price;
                                                return (
                                                    <TableRow key={`${uri}-${index}`}>
                                                        <TableCell>
                                                            <div>
                                                                <b>
                                                                    {productDef?.label} - {productDef?.longName}
                                                                </b>
                                                                {/* <br />#{product._id} */}
                                                                <br />
                                                                {productToString(rfq, uri, product).map(
                                                                    (item, index) => (
                                                                        <div key={index}>{item}</div>
                                                                    ),
                                                                )}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>{productDef?.definition}</TableCell>
                                                        <TableCell align="right">{product?.quantity.value}</TableCell>
                                                        <TableCell align="right">
                                                            {canCalcPrice && <span>{ccyFormat(unitPrice)} €</span>}
                                                            {!canCalcPrice && (
                                                                <span>
                                                                    <Trans t={t}>N/A</Trans>
                                                                </span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {canCalcPrice && <span>{ccyFormat(price)} €</span>}
                                                            {!canCalcPrice && (
                                                                <span>
                                                                    <Trans t={t}>N/A</Trans>
                                                                </span>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </React.Fragment>
                                    );
                                })}

                            <TableRow>
                                <StyledFooterCell colSpan={4}>
                                    <Trans t={t}>Total price</Trans>
                                </StyledFooterCell>
                                <StyledFooterCell align="right">
                                    {totalPrice > 0 && <span>{ccyFormat(totalPrice)} €</span>}
                                    {totalPrice === 0 && (
                                        <span>
                                            <Trans t={t}>N/A</Trans>
                                        </span>
                                    )}
                                </StyledFooterCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {rfq && (
                <>
                    <Typography paragraph style={{ marginTop: 10, marginLeft: 15 }}>
                        {rfq.project.targetDeliveryDate && (
                            <>
                                <Trans t={t}>Estimated date of delivery</Trans>:{' '}
                                {formatDate(rfq.project.targetDeliveryDate, 'LL')} <br />
                            </>
                        )}
                        {rfq.project.incoterm && (
                            <>
                                <Trans t={t}>Incoterm</Trans>: {rfq.project.incoterm}
                                <br />
                            </>
                        )}

                        {/* {t(`Délais : La livraison est prévue 3 semaines après paiement de l'avance`)} */}
                    </Typography>

                    <footer style={{ textAlign: 'center', marginTop: 60 }}>
                        <Typography paragraph>
                            <Trans t={t}>Si les appuis nécessitent des options, un devis spécifique sera réalisé</Trans>
                        </Typography>

                        {confirmed && (
                            <Typography paragraph>
                                Merci pour votre demande, une offre vous sera transmise sur cette plateforme dans les
                                plus brefs délais
                            </Typography>
                        )}
                        {!confirmed && !isAdmin && (
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={handleConfirmRequest}
                                style={{ marginTop: 16 }}
                            >
                                <Trans t={t}>Confirm quote request</Trans>
                            </Button>
                        )}

                        {/* <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => window.postMessage('maximizeChat', '*')}
                    startIcon={<ChatIcon style={{ width: 20 }} />}
                >
                    Discuss with the vendor
                </Button> */}
                    </footer>
                </>
            )}
        </>
    );
};
export default InstantQuote;
