import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { createRFQ as apiCreateRFQ } from 'src/domains/marketplace/api/projects';
import { fetchProjects } from 'src/domains/marketplace/api/projects';
import useCurrentUser from 'src/hooks/useCurrentUser';
import ProjectSelect from '../ProjectSelect';
import { Loader } from 'src/core/components/Loader';
import { Typography } from '@material-ui/core';

const StyledSeparator = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    width: 400px;
    max-width: 80%;
    margin: 32px auto;
    font-size: 1.5em;
    &::before,
    &::after {
        content: '';
        flex: 1;
        border-bottom: 3px solid #ccc;
    }

    &:not(:empty)::before {
        margin-right: 0.5em;
    }

    &:not(:empty)::after {
        margin-left: 0.5em;
    }
`;

const RFQCreate = () => {
    const { currentUser } = useCurrentUser();
    const [project, setProject] = React.useState('');
    const [hasProjects, setHasProjects] = React.useState(false);
    const history = useHistory();
    const { isLoading, data: projects } = useQuery('projects', () => fetchProjects(currentUser));

    const handleCreateWithExistingProject = React.useCallback(async () => {
        const rfq = await apiCreateRFQ(currentUser, project);
        history.push(`/rfq/${rfq.id}`);
    }, [project]);

    const handleProjectChange = (projectId: string) => {
        setProject(projectId);
    };

    const handleCreateRFQ = async () => {
        history.push(`/jobs/new`);
    };

    React.useEffect(() => {
        if (!isLoading && projects) {
            setHasProjects(projects?.length > 0);
        }
    }, [projects, isLoading]);

    return (
        <div>
            <h2>New Request for quotation</h2>

            {isLoading ? (
                <Loader />
            ) : (
                <div style={{ marginBottom: 32, textAlign: 'center' }}>
                    {hasProjects && (
                        <div>
                            Choose an existing Job :
                            <div style={{ margin: 32, marginTop: 0 }}>
                                <ProjectSelect value={project} onChange={handleProjectChange} />
                            </div>
                            {project && (
                                <Button variant="contained" color="primary" onClick={handleCreateWithExistingProject}>
                                    Continue
                                </Button>
                            )}
                            <StyledSeparator>or</StyledSeparator>
                        </div>
                    )}
                    {!hasProjects && <Typography paragraph>You need to create a first Job</Typography>}
                    <Button variant={hasProjects ? 'outlined' : 'contained'} color="primary" onClick={handleCreateRFQ}>
                        {hasProjects ? 'Create a new Job' : 'Continue'}
                    </Button>
                </div>
            )}
        </div>
    );
};
export default RFQCreate;

{
    /* Choose a category of Product to be supplied You can create another RFQ to manage another category of Product
            Target date to receive Quotation */
}
