export const fallbacks = {
    'elastomeric-bearing-constraints': {
        attributes: [
            {
                uri: 'verticalChargeMin',
                type: 'number',
                label: 'Charge verticale minimale (ELU)',
                unit: 'kN',
                default: 0,
            },
            {
                uri: 'verticalChargeMax',
                type: 'number',
                label: 'charge verticale maximale (ELU)',
                unit: 'kN',
                default: 0,
            },
            {
                uri: 'deplacementX',
                type: 'number',
                label: 'déplacement en X',
                unit: 'mm',
                default: 0,
            },
            {
                uri: 'deplacementY',
                type: 'number',
                label: 'déplacement en Y',
                unit: 'mm',
                default: 0,
            },
            {
                uri: 'rotationX',
                type: 'number',
                label: 'rotation en X',
                unit: 'mrad',
                default: 0,
            },
            {
                uri: 'rotationY',
                type: 'number',
                label: 'rotation en Y',
                unit: 'mrad',
                default: 0,
            },
            {
                uri: 'effortHorizontalMaxX',
                type: 'number',
                label: 'effort horizontal maximal en X',
                unit: 'kN',
                default: 0,
            },
            {
                uri: 'effortHorizontalMaxY',
                type: 'number',
                label: 'effort horizontal maximal en X',
                unit: 'kN',
                default: 0,
            },
            {
                uri: 'effortHorizontalConcomittant',
                type: 'number',
                label: 'Effort horizontal concomittant avec la charge verticale minimale',
                unit: 'kN',
                default: 0,
            },
            {
                uri: 'description',
                type: 'textarea',
                label: 'Description',
                default: '',
            },
            {
                uri: 'quantity',
                type: 'picker',
                label: 'Quantity',
                default: 1,
            },
        ],
    },
    'elastomeric-bearing-volumes': {
        attributes: [
            {
                uri: 'volume',
                type: 'number',
                label: 'Volume',
                unit: 'dm3',
                default: 0,
            },
            {
                uri: 'description',
                type: 'textarea',
                label: 'Description',
                default: '',
            },
            {
                uri: 'quantity',
                type: 'picker',
                label: 'Quantity',
                default: 1,
            },
        ],
    },
};

export const elastomericBearingCatalog = [
    {
        category: 'Elastomeric Bearing',
        uri: 'elastomeric-bearing-a',
        label: 'Appui néoprène Type A',
        definition: 'axb ; n(ti+ts) ; 2xe',
        longName: 'Appui néoprène avec une unique frette métallique enrobée par vulcanisation',
        repeat: true,
        main: true,
        legend: true,
        description: `Matériau 			=	Polychloroprène	
        Dureté 			=	60 ShA min	
        Module d’élasticité transversal G			=	0,9 Mpa	± 0,15 Mpa
        Résistance à la traction (MPa)			≥	16	
        Allongement à la rupture			≥	425%	
        Résistance à l’ozone 			=	Pas de fissures	
        Coefficient de frottement à 23°C			=	0,05 à 0,010	
        La nuance d’acier utilisée			=	S235	`,
        attributes: [
            {
                uri: 'a',
                type: 'number',
                label: 'a',
                default: 0,
                unit: 'mm',
                hint: 'Largeur',
                // values: (values: any) => {
                //     return [100, 150, 200];
                // },
            },
            {
                uri: 'b',
                type: 'number',
                label: 'b',
                default: 0,
                unit: 'mm',
                hint: 'Longueur',
                // values: (values: any) => {
                //     if (values.a.value === 100) {
                //         return [250, 200];
                //     } else {
                //         return [100, 150];
                //     }
                // },
            },
            {
                uri: 'ts',
                type: 'number',
                label: 'ts',
                default: 0,
                unit: 'mm',
                hint: 'épaisseur frette acier',
                reco: '3mm pour surface < 120 000mm² / 4mm pour surface < 360 000mm² / 5mm pour surface < 635 000mm²',
            },
            {
                uri: 'e',
                type: 'number',
                label: 'e',
                default: 0,
                unit: 'mm',
                hint: 'épaisseur feuillet néoprène externe',
                reco:
                    '2,5mm	ou	 4mm pour surface < 120 000mm² / 6mm pour surface < 360 000mm² / 8mm pour surface < 635 000mm² / 10mm pour des surfaces > 635 000 mm²',
            },
            {
                uri: 'Tb',
                type: 'integer',
                label: 'Tb',
                default: 0,
                unit: 'mm',
                hint: `hauteur totale de l'appui`,
                calc: (values: any) => {
                    return +values.ts.value + 2 * +values.e.value;
                },
            },

            {
                uri: 'description',
                type: 'textarea',
                label: 'Description',
                default: '',
            },
            {
                uri: 'quantity',
                type: 'picker',
                label: 'Quantité',
                default: 1,
            },
        ],
    },
    {
        category: 'Elastomeric Bearing',
        uri: 'elastomeric-bearing-b',
        label: 'Appui néoprène Type B',
        definition: 'axb ; n(ti+ts) ; 2xe',
        longName: 'Appui néoprène avec au moins deux frettes métalliques enrobées par vulcanisation',
        repeat: true,
        main: true,
        legend: true,
        description: `Matériau 			=	Polychloroprène	
        Dureté 			=	60 ShA min	
        Module d’élasticité transversal G			=	0,9 Mpa	± 0,15 Mpa
        Résistance à la traction (MPa)			≥	16	
        Allongement à la rupture			≥	425%	
        Résistance à l’ozone 			=	Pas de fissures	
        Coefficient de frottement à 23°C			=	0,05 à 0,010	
        La nuance d’acier utilisée			=	S235	`,
        attributes: [
            {
                uri: 'a',
                type: 'number',
                label: 'a',
                default: 0,
                unit: 'mm',
                hint: 'Largeur',
                // values: (values: any) => {
                //     return [100, 150, 200];
                // },
            },
            {
                uri: 'b',
                type: 'number',
                label: 'b',
                default: 0,
                unit: 'mm',
                hint: 'Longueur',
                // values: (values: any) => {
                //     if (values.a.value === 100) {
                //         return [250, 200];
                //     } else {
                //         return [100, 150];
                //     }
                // },
            },
            {
                uri: 'n',
                type: 'number',
                label: 'n',
                default: 0,
                hint: 'Nombre de feuillets néoprènes',
            },
            {
                uri: 'ts',
                type: 'number',
                label: 'ts',
                default: 0,
                unit: 'mm',
                hint: 'épaisseur frette acier',
                reco: ' 3mm pour surface < 120 000mm² / 4mm pour surface < 360 000mm² / 5mm pour surface < 635 000mm²',
            },
            {
                uri: 'ti',
                type: 'number',
                label: 'ti',
                default: 0,
                unit: 'mm',
                hint: 'épaisseur feuillet néoprène interne',
                reco:
                    ' 8mm pour surface < 120 000mm² / 12mm pour surface < 360 000mm² / 16mm pour surface < 635 000mm² / 20mm pour des surfaces > 635 000 mm²',
            },
            {
                uri: 'e',
                type: 'number',
                label: 'e',
                default: 0,
                unit: 'mm',
                hint: 'épaisseur feuillet néoprène externe',
                reco:
                    '2,5mm	ou	 4mm pour surface < 120 000mm² / 6mm pour surface < 360 000mm² / 8mm pour surface < 635 000mm² / 10mm pour des surfaces > 635 000 mm²',
            },
            {
                uri: 'Tb',
                type: 'integer',
                label: 'Tb',
                default: 0,
                unit: 'mm',
                hint: 'Total thickness. This value is automatically calculated based on your specifications',
                calc: (values: any) => {
                    return (
                        +values.n.value * +values.ti.value +
                        (+values.n.value + 1) * +values.ts.value +
                        2 * +values.e.value
                    );
                },
            },
            {
                uri: 'optionFixed',
                type: 'select',
                label: 'Option fixe ou guidé',
                default: 1,
                hint: 'Type de frette acier extérieure',
                helper: `si l'appui nécessite des options, un devis spécifique sera réalisé`,
                options: () => {
                    return ['Appui fixe', 'Appui unidirectionnel'];
                },
            },
            {
                uri: 'description',
                type: 'textarea',
                label: 'Description',
                default: '',
            },
            {
                uri: 'quantity',
                type: 'picker',
                label: 'Quantité',
                default: 1,
            },
        ],
    },
    {
        category: 'Elastomeric Bearing',
        uri: 'elastomeric-bearing-c',
        label: 'Appui néoprène Type C',
        longName:
            "Appui type B complété par des éléments d'anti-cheminement ou d'anti-soulèvement : plaques métalliques extérieures",
        definition: 'axb ; n(ti+ts) + 1A ou axb ; n(ti+ts) + 2A',
        repeat: true,
        main: true,
        legend: true,
        description: `Matériau 			=	Polychloroprène	
        Dureté 			=	60 ShA min	
        Module d’élasticité transversal G			=	0,9 Mpa	± 0,15 Mpa
        Résistance à la traction (MPa)			≥	16	
        Allongement à la rupture			≥	425%	
        Résistance à l’ozone 			=	Pas de fissures	
        Coefficient de frottement à 23°C			=	0,05 à 0,010	
        La nuance d’acier utilisée			=	S235	`,
        attributes: [
            {
                uri: 'a',
                type: 'number',
                label: 'a',
                default: 0,
                unit: 'mm',
                hint: 'Largeur',
            },
            {
                uri: 'b',
                type: 'number',
                label: 'b',
                default: 0,
                unit: 'mm',
                hint: 'Longueur',
            },
            {
                uri: 'n',
                type: 'number',
                label: 'n',
                default: 0,
                hint: 'nombre feuillet néoprène',
            },
            {
                uri: 'ts',
                type: 'number',
                label: 'ts',
                default: 0,
                unit: 'mm',
                hint: "Epaisseur d'une frette en acier",
                reco: ' 3mm pour surface < 120 000mm² / 4mm pour surface < 360 000mm² / 5mm pour surface < 635 000mm²',
            },
            {
                uri: 'ti',
                type: 'number',
                label: 'ti',
                default: 0,
                unit: 'mm',
                hint: 'épaisseur feuillet néoprène interne',
                reco:
                    ' 8mm pour surface < 120 000mm² / 12mm pour surface < 360 000mm² / 16mm pour surface < 635 000mm² / 20mm pour des surfaces > 635 000 mm²',
            },
            {
                uri: 'e',
                type: 'number',
                label: 'e',
                default: 0,
                unit: 'mm',
                hint: 'épaisseur feuillet néoprène externe',
                reco: '2,5mm	ou	ti/2',
            },
            {
                uri: 'A',
                type: 'number',
                label: 'A',
                default: 0,
                unit: 'mm',
                hint: 'Epaisseur de frette acier extérieure superieur',
            },
            {
                uri: 'B',
                type: 'number',
                label: 'B',
                default: 0,
                unit: 'mm',
                hint: 'Epaisseur de frette acier extérieure inferieur',
                reco: `Note : si pas de frette inf. mettre 0mm. Quand il n'y a qu'une frette extérieure, enrobage 2,5 mm sur la face opposée`,
            },
            {
                uri: 'enrobageExterieur',
                type: 'switch',
                label: 'Enrobage latéral de la frette acier extérieure',
                default: false,
                hint: 'Enrobage latéral de la frette acier extérieure',
                reco: 'Note : si enrobage = 5mm	',
            },
            {
                uri: 'Tb',
                type: 'integer',
                label: 'Tb',
                default: 0,
                unit: 'mm',
                hint: `hauteur totale de l'appui`,
                // calc: (values: any) => {
                //     const frettes = +values.frettes.value;
                //     if (!frettes) return 0;
                //     return (
                //         +values.n.value * (+values.ti.value + +values.ts.value) +
                //         frettes * +values.A.value +
                //         (2 - frettes) * 2.5 -
                //         (frettes - 1) * +values.ts.value
                //     );
                // },
            },
            {
                uri: 'optionFretteType',
                type: 'select',
                label: 'Option sur le type de frette acier extérieure',
                options: () => {
                    return ['Lisse', 'Striée'];
                },
                isOption: false,
            },
            {
                uri: 'optionAntiCheminement',
                type: 'switch',
                label: 'Option de dispositif anti-cheminement (platine + taquet)',
                default: false,
                isOption: false,
            },
            {
                uri: 'optionDemontable',
                type: 'switch',
                label: `Option de démontabilité de l'appui`,
                default: false,
                isOption: false,
            },
            {
                uri: 'optionAntiSoulevement',
                type: 'switch',
                label: `Option anti-soulèvement`,
                default: false,
                isOption: false,
            },
            {
                uri: 'description',
                type: 'textarea',
                label: 'Description',
                default: '',
            },
            {
                uri: 'quantity',
                type: 'picker',
                label: 'Quantité',
                default: 1,
            },
        ],
    },

    {
        category: 'Elastomeric Bearing',
        uri: 'elastomeric-bearing-d',
        label: 'Appui néoprène Type D',
        longName: 'Appui type B complété par des éléments de glissement : feuille de PTFE en surface',
        definition: 'axb ; n(ti+ts) + 1A ou axb ; n(ti+ts) + 2A',
        repeat: true,
        main: true,
        legend: true,
        description: `Matériau 			=	Polychloroprène			
Dureté 			=	60 ShA min			
Module d’élasticité transversal G			=	0,9 Mpa	± 0,15 Mpa		
Résistance à la traction (MPa)			≥	16			
Allongement à la rupture			≥	425%			
Résistance à l’ozone 			=	Pas de fissures			
Coefficient de frottement à 23°C			=	0,05 à 0,010			
La nuance d’acier utilisée			=	S235			
PTFE			=	Polytétrafkuoréthylène pur sans matériaux recyclé ni charge		
Plaque support			=	S235			`,
        attributes: [
            {
                uri: 'a',
                type: 'number',
                label: 'a',
                default: 0,
                unit: 'mm',
                hint: 'Petit coté',
            },
            {
                uri: 'b',
                type: 'number',
                label: 'b',
                default: 0,
                unit: 'mm',
                hint: 'Grand coté',
            },
            {
                uri: 'n',
                type: 'number',
                label: 'n',
                default: 0,
                hint: 'Nombre de feuillets néoprène interne',
            },
            {
                uri: 'ti',
                type: 'number',
                label: 'ti',
                default: 0,
                unit: 'mm',
                hint: "Epaisseur d'un feuillet néoprène interne",
            },
            {
                uri: 'ts',
                type: 'number',
                label: 'ts',
                default: 0,
                unit: 'mm',
                hint: "Epaisseur d'une frette en acier",
            },
            {
                uri: 'fretteType',
                type: 'select',
                label: 'Type de frette acier extérieure',
                default: 1,
                hint: 'Type de frette acier extérieure',
                options: () => {
                    return ['Lisse', 'Striée'];
                },
            },
            {
                uri: 'enrobageExterieur',
                type: 'switch',
                label: 'Enrobage latéral de la frette acier extérieure',
                default: 1,
                hint: 'Enrobage latéral de la frette acier extérieure',
            },
            {
                uri: 'antisoulevement',
                type: 'switch',
                label: 'option anti-soulèvement',
                default: 1,
                hint: 'option anti-soulèvement',
            },
            {
                uri: 'demontable',
                type: 'switch',
                label: 'option démontable',
                default: 1,
                hint: 'option démontable',
            },
            {
                uri: 'frettes',
                type: 'number',
                label: 'Frettes',
                default: 1,
                hint: 'Nombre de frettes',
            },
            {
                uri: 'Tb',
                type: 'integer',
                label: 'Tb',
                default: 0,
                unit: 'mm',
                hint: 'Total thickness. This value is automatically calculated based on your specifications',
                calc: (values: any) => {
                    const frettes = +values.frettes.value;
                    if (!frettes) return 0;
                    return (
                        +values.n.value * (+values.ti.value + +values.ts.value) +
                        frettes * +values.A.value +
                        (2 - frettes) * 2.5 -
                        (frettes - 1) * +values.ts.value
                    );
                },
            },
            {
                uri: 'description',
                type: 'textarea',
                label: 'Description',
                default: '',
            },
            {
                uri: 'quantity',
                type: 'picker',
                label: 'Quantité',
                default: 1,
            },
        ],
    },
    {
        category: 'Elastomeric Bearing',
        uri: 'elastomeric-bearing-e',
        label: 'Appui néoprène Type E',
        longName:
            'Appui type B complété par des éléments de glissement : plaque métallique en surface en contact avec une feuille de PTFE',
        definition: 'axb ; n(ti+ts) + 1A ou axb ; n(ti+ts) + 2A',
        repeat: true,
        main: true,
        legend: true,
        inStock: false,

        attributes: [
            {
                uri: 'a',
                type: 'number',
                label: 'a',
                default: 0,
                unit: 'mm',
                hint: 'Petit coté',
            },
            {
                uri: 'b',
                type: 'number',
                label: 'b',
                default: 0,
                unit: 'mm',
                hint: 'Grand coté',
            },
            {
                uri: 'n',
                type: 'number',
                label: 'n',
                default: 0,
                hint: 'Nombre de feuillets néoprène interne',
            },
            {
                uri: 'ti',
                type: 'number',
                label: 'ti',
                default: 0,
                unit: 'mm',
                hint: "Epaisseur d'un feuillet néoprène interne",
            },
            {
                uri: 'ts',
                type: 'number',
                label: 'ts',
                default: 0,
                unit: 'mm',
                hint: "Epaisseur d'une frette en acier",
            },
            {
                uri: 'A',
                type: 'number',
                label: 'A',
                default: 0,
                unit: 'mm',
                hint: 'Epaisseur de frette acier extérieure sup.',
            },
            {
                uri: 'B',
                type: 'number',
                label: 'B',
                default: 0,
                unit: 'mm',
                hint: 'Epaisseur de frette acier extérieure inf.',
            },
            {
                uri: 'fretteType',
                type: 'select',
                label: 'Type de frette acier extérieure',
                default: 1,
                hint: 'Type de frette acier extérieure',
                options: () => {
                    return ['Lisse', 'Striée'];
                },
            },
            {
                uri: 'enrobageExterieur',
                type: 'switch',
                label: 'Enrobage latéral de la frette acier extérieure',
                default: false,
                hint: 'Enrobage latéral de la frette acier extérieure',
            },
            {
                uri: 'antisoulevement',
                type: 'switch',
                label: 'option anti-soulèvement',
                default: 1,
                hint: 'option anti-soulèvement',
            },
            {
                uri: 'demontable',
                type: 'switch',
                label: 'option démontable',
                default: 1,
                hint: 'option démontable',
            },
            {
                uri: 'frettes',
                type: 'number',
                label: 'Frettes',
                default: 1,
                hint: 'Nombre de frettes',
            },
            {
                uri: 'Tb',
                type: 'integer',
                label: 'Tb',
                default: 0,
                unit: 'mm',
                hint: 'Total thickness. This value is automatically calculated based on your specifications',
                calc: (values: any) => {
                    const frettes = +values.frettes.value;
                    if (!frettes) return 0;
                    return (
                        +values.n.value * (+values.ti.value + +values.ts.value) +
                        frettes * +values.A.value +
                        (2 - frettes) * 2.5 -
                        (frettes - 1) * +values.ts.value
                    );
                },
            },
            {
                uri: 'description',
                type: 'textarea',
                label: 'Description',
                default: '',
            },
            {
                uri: 'quantity',
                type: 'picker',
                label: 'Quantité',
                default: 1,
            },
        ],
    },
    {
        category: 'Elastomeric Bearing',
        uri: 'elastomeric-bearing-f',
        label: 'Appui néoprène Type F',
        longName: 'Appui néoprène non fretté',
        definition: 'axb ; n(ti+ts) + 1A ou axb ; n(ti+ts) + 2A',
        repeat: true,
        main: true,
        legend: true,
        // inStock: false,

        attributes: [
            {
                uri: 'a',
                type: 'number',
                label: 'a',
                default: 0,
                unit: 'mm',
                hint: 'Petit coté',
            },
            {
                uri: 'b',
                type: 'number',
                label: 'b',
                default: 0,
                unit: 'mm',
                hint: 'Grand coté',
            },
            {
                uri: 'n',
                type: 'number',
                label: 'n',
                default: 0,
                hint: 'Nombre de feuillets néoprène interne',
            },
            {
                uri: 'ti',
                type: 'number',
                label: 'ti',
                default: 0,
                unit: 'mm',
                hint: "Epaisseur d'un feuillet néoprène interne",
            },
            {
                uri: 'ts',
                type: 'number',
                label: 'ts',
                default: 0,
                unit: 'mm',
                hint: "Epaisseur d'une frette en acier",
            },
            {
                uri: 'A',
                type: 'number',
                label: 'A',
                default: 0,
                unit: 'mm',
                hint: 'Epaisseur de frette acier extérieure sup.',
            },
            {
                uri: 'B',
                type: 'number',
                label: 'B',
                default: 0,
                unit: 'mm',
                hint: 'Epaisseur de frette acier extérieure inf.',
            },
            {
                uri: 'fretteType',
                type: 'select',
                label: 'Type de frette acier extérieure',
                default: 1,
                hint: 'Type de frette acier extérieure',
                options: () => {
                    return ['Lisse', 'Striée'];
                },
            },
            {
                uri: 'enrobageExterieur',
                type: 'switch',
                label: 'Enrobage latéral de la frette acier extérieure',
                default: 1,
                hint: 'Enrobage latéral de la frette acier extérieure',
            },
            {
                uri: 'antisoulevement',
                type: 'switch',
                label: 'option anti-soulèvement',
                default: 1,
                hint: 'option anti-soulèvement',
            },
            {
                uri: 'demontable',
                type: 'switch',
                label: 'option démontable',
                default: 1,
                hint: 'option démontable',
            },
            {
                uri: 'frettes',
                type: 'number',
                label: 'Frettes',
                default: 1,
                hint: 'Nombre de frettes',
            },
            {
                uri: 'Tb',
                type: 'integer',
                label: 'Tb',
                default: 0,
                unit: 'mm',
                hint: 'Total thickness. This value is automatically calculated based on your specifications',
                calc: (values: any) => {
                    const frettes = +values.frettes.value;
                    if (!frettes) return 0;
                    return (
                        +values.n.value * (+values.ti.value + +values.ts.value) +
                        frettes * +values.A.value +
                        (2 - frettes) * 2.5 -
                        (frettes - 1) * +values.ts.value
                    );
                },
            },
            {
                uri: 'description',
                type: 'textarea',
                label: 'Description',
                default: '',
            },
            {
                uri: 'quantity',
                type: 'picker',
                label: 'Quantity',
                default: 1,
            },
        ],
    },
    {
        category: 'Elastomeric Bearing',
        uri: 'elastomeric-bearing-misc',
        label: 'Autres Appuis néoprènes',
        definition: 'axb ; n(ti+ts) ; 2xe',
        longName: "Appareil d'appui néoprène Divers",
        repeat: true,
        main: true,
        legend: false,
        expandable: false,
        attributes: [
            {
                uri: 'description',
                type: 'textarea',
                label: 'Description',
                default: '',
            },
            {
                uri: 'quantity',
                type: 'picker',
                label: 'Quantité',
                default: 1,
            },
        ],
    },
];
export const elastomericBearingConstraintsCatalog = elastomericBearingCatalog.map((x) => {
    return {
        ...x,
        uri: `${x.uri}`,
        attributes: [
            ...fallbacks['elastomeric-bearing-constraints'].attributes,
            ...(x.attributes as any[]).filter((x: any) => x.isOption),
        ],
    };
});
export const elastomericBearingVolumesCatalog = elastomericBearingCatalog.map((x) => {
    return {
        ...x,
        uri: `${x.uri}`,
        attributes: [
            ...fallbacks['elastomeric-bearing-volumes'].attributes,
            ...(x.attributes as any[]).filter((x: any) => x.isOption),
        ],
    };
});

export const CATALOG = (elastomericBearingCatalog as Array<any>)
    .concat(elastomericBearingVolumesCatalog)
    .concat(elastomericBearingConstraintsCatalog);
