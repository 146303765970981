import React from 'react';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Block, Create, DoneAll, DoneOutline, EmojiObjects, ShoppingCart } from '@material-ui/icons';

export const RFQStatus = ({ rfq }: { rfq: any }) => {
    const statusOptions = [
        { label: 'Chiffrage initié', icon: <Create style={{ fill: 'orange' }} /> },
        { label: 'Chiffrage initié', icon: <Create style={{ fill: 'orange' }} /> },
        { label: 'Chiffrage en cours', icon: <MoreHorizIcon style={{ fill: 'orange' }} /> },
        { label: 'Chiffrage reçu', icon: <EmojiObjects style={{ fill: 'green' }} /> },
        { label: 'Commande passée', icon: <ShoppingCart style={{ fill: 'green' }} /> },
        { label: 'Job terminé', icon: <DoneAll style={{ fill: 'green' }} /> },
        { label: 'Chiffrage abandonné', icon: <Block style={{ fill: 'red' }} /> },
    ];
    const statusOption = statusOptions[rfq.status || 0];

    return <Chip variant="outlined" size="medium" icon={statusOption.icon} label={statusOption.label} />;
};
