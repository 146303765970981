import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Button, CardActions } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import clsx from 'clsx';

import { ProductAttributeField } from './ProductAttributeField';
// import { DocumentFolder } from 'src/core/components/DocumentFolder/DocumentFolder';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { AttributeList } from './types';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 450,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

type Props = {
    data?: any;
    product: any;
    index: number;
    productDef: any;
    uri: string;
    className: string;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    onDelete: (event: React.MouseEvent<HTMLElement>) => void;
    isActive: boolean;
    id: string;
};

export const ProductCardForm = ({ id, data, uri, index, productDef, onClick, isActive, onDelete }: Props) => {
    const classes = useStyles();
    const { expandable } = productDef;
    const { currentUser } = useCurrentUser();
    const [expanded, setExpanded] = React.useState(expandable === false ? true : false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const attributes: AttributeList = productDef.attributes;

    const quantityAttr = attributes.find((x: any) => x.uri === 'quantity');
    const descAttr = attributes.find((x: any) => x.uri === 'description');

    return (
        <Card elevation={2} className={classes.root} onClick={onClick} variant={isActive ? `outlined` : undefined}>
            <CardContent>
                {expandable !== false && (
                    <fieldset>
                        <Typography variant="overline">SPECIFICATIONS TECHNIQUES</Typography>

                        {attributes
                            .filter((x: any) => !['description', 'quantity'].includes(x.uri))
                            .map((attr: any) => {
                                const name = `${uri}[${index}].${attr.uri}.value`;
                                return (
                                    <div key={`${uri}-${index}-${attr.uri}`} style={{ marginBottom: 4 }}>
                                        <ProductAttributeField name={name} attributeDef={attr} />
                                    </div>
                                );
                            })}
                    </fieldset>
                )}

                {quantityAttr && (
                    <fieldset>
                        <Typography variant="overline">Quantité</Typography>

                        <ProductAttributeField
                            name={`${uri}[${index}].${quantityAttr.uri}.value`}
                            attributeDef={quantityAttr}
                            showLabel={false}
                        />
                    </fieldset>
                )}
            </CardContent>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <fieldset>
                        <Typography>Reference: #{id}</Typography>
                        {/* <Typography variant="body2">
                            {`Note : si l'appui nécessite des options, un devis spécifique sera réalisé`}
                        </Typography> */}
                    </fieldset>

                    {/* {descAttr && (
                        <fieldset>
                            <Typography variant="overline">DESCRIPTION</Typography>

                            <ProductAttributeField
                                name={`${uri}[${index}].${descAttr.uri}.value`}
                                attributeDef={descAttr}
                                showLabel={false}
                            />
                        </fieldset>
                    )}

                    <fieldset>
                        <DocumentFolder
                            uri={`products/${currentUser.id}/products/${uri}/${index}`}
                            label={'Documents'}
                        />
                    </fieldset> */}
                </CardContent>
            </Collapse>

            <CardActions disableSpacing>
                <IconButton aria-label="Delete" onClick={onDelete} size="small">
                    <CloseRoundedIcon color="error" />
                </IconButton>

                {expandable !== false && (
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                )}
            </CardActions>
        </Card>
    );
};
