import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { ConnectForm } from './ConnectForm';
import { Tooltip } from '@material-ui/core';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import Checkbox from '@material-ui/core/Checkbox';
import { Controller } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import { QuantityPicker } from 'react-qty-picker';

export const ProductAttributeField = ({
    name,
    attributeDef,
    showLabel = true,
}: {
    name: string;
    attributeDef: any;
    showLabel?: boolean;
}): JSX.Element => {
    const isAutoCalc = !!attributeDef.calc;

    const getField = ({ name, control, ...methods }: { name: string; control: any }): JSX.Element => {
        // console.log({ ...methods });

        switch (attributeDef.type) {
            case 'switch':
                return (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={attributeDef.default}
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                            <Switch checked={value} onBlur={onBlur} onChange={onChange} inputRef={ref} />
                        )}
                    />
                );
            case 'number':
            case 'integer':
                return (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={attributeDef.default}
                        render={({ field: { onChange, onBlur, value, name, ref } }) => {
                            // console.log(value);
                            return (
                                <TextField
                                    disabled={isAutoCalc}
                                    className={isAutoCalc ? 'ptnv-autocalc' : ''}
                                    onBlur={onBlur}
                                    onChange={(e) => {
                                        // e.target.value.length === 0 ? onChange(undefined) : onChange(+e.target.value);
                                        onChange(+e.target.value);
                                    }}
                                    value={value}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: { min: 0 },
                                        disableUnderline: false,
                                        endAdornment: !!!attributeDef.unit ? null : (
                                            <InputAdornment position="end">{attributeDef.unit}</InputAdornment>
                                        ),
                                    }}
                                />
                            );
                        }}
                    />
                );

            case 'picker':
                return (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={attributeDef.default}
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                            <QuantityPicker smooth min={1} value={value} onChange={onChange} />
                        )}
                    />
                );

            case 'text':
                return (
                    <TextField
                        InputProps={{
                            type: 'text',
                            disableUnderline: false,
                        }}
                        {...methods}
                    />
                );
            case 'textarea':
                return (
                    <TextareaAutosize
                        {...methods}
                        // InputProps={{
                        //     type: 'text',
                        //     disableUnderline: true,
                        // }}
                    />
                );
            case 'select':
                // const formValues = options?.getValues();
                // const productValues = formValues[options.uri][options.index];
                // const optionsValues = attributeDef.values(productValues);
                const optionsValues = attributeDef.options();
                return (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={attributeDef.default}
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                            <>
                                <Select
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        onChange(e.target.value);
                                    }}
                                    style={{ minWidth: 100 }}
                                >
                                    {optionsValues?.map((value: string) => (
                                        <MenuItem key={value} value={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {attributeDef.helper && <FormHelperText>{attributeDef.helper}</FormHelperText>}
                            </>
                        )}
                    />
                );
            default:
                return <Switch {...methods} />;
        }
    };

    // const hint =
    //     (attributeDef.hint || '') +
    //     (attributeDef.calc ? 'This value is automatically calculated based on your specifications' : '');

    // const hint = attributeDef.hint + '\n' + attributeDef.reco;
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {showLabel && (
                <div style={{ paddingRight: 16, width: '70%' }}>
                    <Tooltip
                        title={
                            <div>
                                {attributeDef.hint}
                                {attributeDef.reco && (
                                    <div
                                        style={{
                                            borderTop: '1px dashed #999',
                                            paddingTop: 5,
                                            marginTop: 5,
                                            color: '#eee',
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        {attributeDef.reco}
                                    </div>
                                )}
                            </div>
                        }
                    >
                        <label style={{ textAlign: 'right' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                {attributeDef.calc && (
                                    <OfflineBoltIcon
                                        style={{
                                            width: 16,
                                            marginRight: 3,
                                            color: '#9B9B9B',
                                        }}
                                    />
                                )}
                                <div>{attributeDef.label}</div>
                            </div>
                        </label>
                    </Tooltip>
                </div>
            )}
            <div style={!showLabel ? { width: '100%' } : { width: 100 }}>
                <ConnectForm>
                    {({ register, control }) => {
                        const Field = getField({ control, ...register(name) });
                        return Field;
                    }}
                </ConnectForm>
            </div>
        </div>
    );
};
