import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { pick } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

// import { USER_STATUS, USER_ROLES } from 'src/constants';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { DocumentFolder } from 'src/core/components/DocumentFolder/DocumentFolder';
import { ProductFamilyPicker } from 'src/components/ProductFamilyPicker';
import { updateMarketplaceUser } from 'src/domains/marketplace/api/users';
import CountrySelect from 'src/components/CountrySelect';

type FormInputs = {
    firstName: string;
    lastName: string;
    productFamilies: Array<string | null>;
    company: { name: string; phone: string; address: string; duns: string; siret: string; country: string };
    jobTitle: string;
    email: string;
    mobilePhone: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);

type BuyerOnboardingFormProps = {
    onUpdateUser?: any;
    submitLabel?: string;
};
interface stateType {
    submitLabel: string;
}

const BuyerProfile = ({ onUpdateUser, submitLabel }: BuyerOnboardingFormProps) => {
    const classes = useStyles();
    const { currentUser, authUser, setCurrentUser } = useCurrentUser();
    const { state: locationState } = useLocation<stateType>();
    const { register, setValue, handleSubmit, watch, control } = useForm<FormInputs>({
        defaultValues: pick(currentUser, ['firstName', 'lastName', 'company', 'jobTitle', 'email', 'mobilePhone']),
    });
    const { t } = useTranslation();
    submitLabel = locationState?.submitLabel || submitLabel || t('Submit');

    // const watchFields = watchAll;
    const onSubmit = React.useCallback(
        async (data: any) => {
            console.log(data as string, authUser);

            await updateMarketplaceUser(data, currentUser, authUser);
            setCurrentUser({ ...currentUser, ...data });
            // hack
            setTimeout(() => {
                if (onUpdateUser) {
                    onUpdateUser(data);
                }
            }, 1000);
        },
        [currentUser, authUser],
    );

    // const handleChange = (event: any) => {
    //     // setValue((event.target as HTMLInputElement).value);
    //     props.updateUser();
    // };
    return (
        <div className={classes.root}>
            <header>
                <Typography variant="h4">
                    <Trans t={t}>Buyer Profile</Trans>
                </Typography>
                <Typography paragraph>
                    <Trans t={t}>Create your buyer profile to start using the platform</Trans>
                </Typography>
            </header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} style={{ maxWidth: 600 }}>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field }) => (
                                <TextField {...field} fullWidth required label={t('First name')} variant="standard" />
                            )}
                            name="firstName"
                            control={control}
                            defaultValue={''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            render={({ field }) => (
                                <TextField {...field} fullWidth required label={t('Last name')} variant="standard" />
                            )}
                            name="lastName"
                            control={control}
                            defaultValue={''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    required
                                    label={t('Email')}
                                    variant="standard"
                                    disabled
                                />
                            )}
                            name="email"
                            control={control}
                            defaultValue={''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    required
                                    label={t('Mobile Phone Number')}
                                    variant="standard"
                                />
                            )}
                            name="mobilePhone"
                            control={control}
                            defaultValue={''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    required
                                    label={t('Position in the company')}
                                    variant="standard"
                                />
                            )}
                            name="jobTitle"
                            control={control}
                            defaultValue={''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            render={({ field }) => (
                                <TextField {...field} fullWidth required label={t('Company Name')} variant="standard" />
                            )}
                            name="company.name"
                            control={control}
                            defaultValue={''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    required
                                    label={t('Company address')}
                                    variant="standard"
                                />
                            )}
                            name="company.address"
                            control={control}
                            defaultValue={''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                            <FormLabel component="legend">{t('Country')}</FormLabel>

                            <Controller
                                render={({ field }) => <CountrySelect {...field} />}
                                name="company.country"
                                control={control}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            render={({ field }) => (
                                <TextField {...field} fullWidth label={t('Company SIRET Number')} variant="standard" />
                            )}
                            name="company.siret"
                            control={control}
                            defaultValue={''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            render={({ field }) => (
                                <TextField {...field} fullWidth label={t('Company DUNS Number')} variant="standard" />
                            )}
                            name="company.duns"
                            control={control}
                            defaultValue={''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DocumentFolder
                            uri={`profile/${currentUser.id}/company/presentation`}
                            label={t('Presentation of your company')}
                        />
                    </Grid>

                    {/* <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">productFamilies</FormLabel>

                            <Controller as={<ProductFamilyPicker />} name="productFamilies" control={control} />
                        </FormControl>
                    </Grid> */}

                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit" size="large">
                            {submitLabel}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default BuyerProfile;
