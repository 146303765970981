export const USER_STATUS = {
    NEW: 0,
    ONBOARDED: 1,
};

export const USER_ROLES = {
    BROKER: 'BROKER',
    BUYER: 'BUYER',
    VENDOR: 'VENDOR',
};

export const STRUCTURE_TYPE = {
    BRIDGE: 'bridge',
    ROAD: 'road',
};

export const RFQ_STATUS = {
    NEW: 0,
    QUOTE_REQUESTED: 1,
    QUOTE_REQUEST_CONFIRMED: 2,
    QUOTE_REQUEST_ACKNOWLEDGED: 3,
    ORDERED: 4,
    COMPLETED: 5,
    CANCELLED: 6,
};
