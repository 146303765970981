import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const StyledBreadcrumbs = withTheme(styled.div`
    margin-bottom: 32px;
    a,
    p {
        /* color: #fff; */
        padding: 8px 0;
        display: inline-block;
        border-bottom: 3px solid transparent;
    }
    a:hover {
        border-bottom: 3px solid ${(props) => props.theme.palette.primary.main};
    }
`);

type BreadcrumbsProps = {
    crumbs: Array<{ name: string | React.ReactNode; path: string }>;
};
const Breadcrumb = ({ crumbs }: BreadcrumbsProps) => {
    const { t } = useTranslation();
    return (
        <StyledBreadcrumbs>
            <Breadcrumbs aria-label="breadcrumb">
                <RouterLink color="inherit" to="/">
                    {t('Dashboard')}
                </RouterLink>
                {crumbs.map(({ name, path }, key) => {
                    return key + 1 === crumbs.length ? (
                        <Typography key={key} color="textPrimary">
                            {name}
                        </Typography>
                    ) : (
                        <RouterLink key={key} color="inherit" to={path}>
                            {name}
                        </RouterLink>
                    );
                })}
            </Breadcrumbs>
        </StyledBreadcrumbs>
    );
};

export default Breadcrumb;
