// import { nanoid } from 'nanoid';
import { customAlphabet } from 'nanoid';

import dayjs from 'dayjs';
require('dayjs/locale/fr');
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);
dayjs.locale('fr');

export const formatDate = (date: string, pattern = 'LL') => {
    return dayjs(date).format(pattern);
};

export const sortByDate = (list: any[], key = 'createdAt', order = 'ASC') => {
    if (!list) return list;
    const result = list.sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime());
    return order === 'DESC' ? result.reverse() : result;
};

export const getUUID = (size = 8) => {
    const nanoid = customAlphabet('1234567890ABCDEFGH', size);
    return nanoid();
};

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export function countryToFlag(isoCode: string) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}
