import * as React from 'react';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { onCreateProjectMessageByProjectId } from 'src/graphql/subscriptions';
import { createProjectMessage } from 'src/graphql/mutations';
import Amplify, { API, graphqlOperation } from 'aws-amplify';

import { ChannelWebview } from './ChannelWebview';
import styled from 'styled-components';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { ChevronDownIcon } from '@heroicons/react/solid';
// import { MinusSmIcon } from '@heroicons/react/solid';
import useProjects from 'src/hooks/useProjects';
import MailIcon from '@material-ui/icons/Mail';
import Badge from '@material-ui/core/Badge';
import ScrollableFeed from 'react-scrollable-feed';
import Avatar from 'react-avatar';

import { Trans, useTranslation } from 'react-i18next';
import { listProjectMessages } from 'src/graphql/queries';

// RCE CSS
// import 'react-chat-elements/dist/main.css';
// MessageBox component
// import { MessageList } from 'react-chat-elements';
import { ListProjectMessagesQuery } from 'src/API';
import { formatDate, sortByDate } from 'src/helpers';
import { DocumentFolder } from '../../../../core/components/DocumentFolder/DocumentFolder';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import { ListItemAvatar } from '@material-ui/core';

type Props = {
    // projectId?: string;
    project?: any;
    showDocuments?: boolean;
};

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: '100%',
    },
    headBG: {
        // backgroundColor: '#e0e0e0',
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0',
    },
    messageArea: {
        height: 'calc(100vh - 390px)',
        width: '100%',
        overflowY: 'auto',
        padding: '0 15px',
    },
    inline: {
        display: 'inline',
    },
});
type MessageInputProps = {
    addMessage: (message: any) => Promise<boolean>;
};
const MessageInput = ({ addMessage }: MessageInputProps) => {
    const [message, setMessage] = React.useState('');

    const handleKeyPress = React.useCallback(
        (event: any) => {
            if (event.key === 'Enter') {
                sendMessage();
            }
        },
        [message],
    );
    const sendMessage = React.useCallback(() => {
        if (message.length === 0) return;
        addMessage(message);
        setMessage('');
    }, [message]);

    return (
        <Grid container style={{ padding: '0 20px', marginTop: 10 }}>
            <Grid item xs={10}>
                <TextField
                    id="outlined-basic-email"
                    label={''}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleKeyPress}
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <Fab color="primary" aria-label="add">
                    <SendIcon onClick={sendMessage} />
                </Fab>
            </Grid>
        </Grid>
    );
};

export const ProjectChat = ({ project }: { project?: any }) => {
    const { currentUser } = useCurrentUser();
    // const { project } = useProjects();
    const [messages, setMessages] = React.useState<Array<any>>([]);
    const messagesRef = React.useRef(messages);

    const classes = useStyles();

    const pushMessage = React.useCallback(
        (message) => {
            const updated = [...messagesRef.current, message];
            messagesRef.current = updated;
            setMessages(updated);
        },
        [messages],
    );

    const addMessage = React.useCallback(
        async (message) => {
            API.graphql(
                graphqlOperation(createProjectMessage, {
                    input: { projectId: project.id, content: message, userId: currentUser.id },
                }),
            );
            return true;
        },
        [project, currentUser],
    );

    const subscribe = async (): Promise<any> => {
        const subscription = (API.graphql(
            graphqlOperation(onCreateProjectMessageByProjectId, { projectId: project.id }),
        ) as any).subscribe({
            next: ({ provider, value }: { provider: any; value: any }) => {
                pushMessage(value.data.onCreateProjectMessageByProjectId);
                console.log('new msg', { provider, value });
            },
            error: (error: any) => console.warn(error),
        });
        return subscription;
    };
    const fetchMessages = async () => {
        const data = (await API.graphql(
            graphqlOperation(listProjectMessages, { filter: { projectId: { eq: project.id } } }),
        )) as {
            data: ListProjectMessagesQuery;
        };
        if (data.data?.listProjectMessages?.items) {
            setMessages(data.data.listProjectMessages?.items);
            messagesRef.current = data.data.listProjectMessages?.items;
            // console.log(data.data.listProjectMessages?.items);
        }
    };
    React.useEffect(() => {
        if (!project || !currentUser) return;
        console.log('fetching');
        fetchMessages();

        let subscription: any;
        (async () => {
            subscription = await subscribe();
        })();

        return () => subscription && subscription.unsubscribe();
    }, [project, currentUser]);

    return (
        <div className="chat">
            {/* <Grid item xs={12}> */}
            <div className={classes.messageArea}>
                <ScrollableFeed>
                    {sortByDate(messages, 'createdAt', 'ASC').map((message) => {
                        return (
                            <ListItem key={message.id}>
                                <ListItemAvatar style={{ width: 40, minWidth: 40 }}>
                                    <Avatar name={message.author.fullName} size="32" round={true} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <b style={{ fontSize: 13 }}>{message.author.fullName} </b>
                                            <span style={{ fontSize: 11, color: '#999' }}>
                                                {formatDate(message.createdAt, 'L LT')}
                                            </span>
                                        </React.Fragment>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            ></Typography>
                                            {message.content}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </ScrollableFeed>
            </div>
            {/* </Grid> */}
            <MessageInput addMessage={addMessage} />
        </div>
    );
};

const ProjectInbox = ({ project, showDocuments = true }: Props) => {
    // const { currentUser } = useCurrentUser();
    const { project: currentProject } = useProjects();
    project = project || currentProject;

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div>
            {/* <Grid container component={Paper} className={classes.chatSection}> */}
            {/* <Grid item xs={12}> */}
            {showDocuments && (
                <div style={{ padding: 10 }}>
                    <DocumentFolder
                        uri={`projects/${project.id}/files`}
                        label={t('Shared Documents')}
                        projectId={project.id}
                    />
                </div>
            )}
            {/* </Grid> */}
            <ProjectChat project={project} />
        </div>
        // </Grid>
    );
};
export default ProjectInbox;
