/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateProjectMessageByProjectId = /* GraphQL */ `
  subscription OnCreateProjectMessageByProjectId($projectId: String!) {
    onCreateProjectMessageByProjectId(projectId: $projectId) {
      id
      projectId
      userId
      content
      author {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDocumentByFolderUri = /* GraphQL */ `
  subscription OnCreateDocumentByFolderUri($folderUri: String!) {
    onCreateDocumentByFolderUri(folderUri: $folderUri) {
      id
      name
      size
      contentType
      S3Key
      projectId
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      folderId
      folderUri
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String!) {
    onCreateUser(owner: $owner) {
      id
      email
      owner
      username
      fullName
      firstName
      lastName
      mobilePhone
      role
      status
      company {
        name
        address
        country
        website
        phone
        duns
        siret
        countries
        productFamilies
      }
      jobTitle
      authUserId
      chat {
        userId
        loginToken
      }
      directChannelId
      directChannel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      activityFeeds {
        items {
          id
          userId
          activityFeedId
          feed {
            id
            uri
            activities {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String!) {
    onUpdateUser(owner: $owner) {
      id
      email
      owner
      username
      fullName
      firstName
      lastName
      mobilePhone
      role
      status
      company {
        name
        address
        country
        website
        phone
        duns
        siret
        countries
        productFamilies
      }
      jobTitle
      authUserId
      chat {
        userId
        loginToken
      }
      directChannelId
      directChannel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      activityFeeds {
        items {
          id
          userId
          activityFeedId
          feed {
            id
            uri
            activities {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String!) {
    onDeleteUser(owner: $owner) {
      id
      email
      owner
      username
      fullName
      firstName
      lastName
      mobilePhone
      role
      status
      company {
        name
        address
        country
        website
        phone
        duns
        siret
        countries
        productFamilies
      }
      jobTitle
      authUserId
      chat {
        userId
        loginToken
      }
      directChannelId
      directChannel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      activityFeeds {
        items {
          id
          userId
          activityFeedId
          feed {
            id
            uri
            activities {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($owner: String) {
    onCreateProject(owner: $owner) {
      id
      name
      pitch
      status
      prettyName
      productFamilies
      country
      incoterm
      shippingAddress
      structureType
      targetDeliveryDate
      members {
        items {
          id
          projectId
          userId
          role
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      channels {
        items {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rfqs {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isUrgentDelivery
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($owner: String) {
    onUpdateProject(owner: $owner) {
      id
      name
      pitch
      status
      prettyName
      productFamilies
      country
      incoterm
      shippingAddress
      structureType
      targetDeliveryDate
      members {
        items {
          id
          projectId
          userId
          role
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      channels {
        items {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rfqs {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isUrgentDelivery
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject($owner: String) {
    onDeleteProject(owner: $owner) {
      id
      name
      pitch
      status
      prettyName
      productFamilies
      country
      incoterm
      shippingAddress
      structureType
      targetDeliveryDate
      members {
        items {
          id
          projectId
          userId
          role
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      channels {
        items {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rfqs {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isUrgentDelivery
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProjectMember = /* GraphQL */ `
  subscription OnCreateProjectMember {
    onCreateProjectMember {
      id
      projectId
      userId
      role
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProjectMember = /* GraphQL */ `
  subscription OnUpdateProjectMember {
    onUpdateProjectMember {
      id
      projectId
      userId
      role
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProjectMember = /* GraphQL */ `
  subscription OnDeleteProjectMember {
    onDeleteProjectMember {
      id
      projectId
      userId
      role
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProjectMessage = /* GraphQL */ `
  subscription OnCreateProjectMessage {
    onCreateProjectMessage {
      id
      projectId
      userId
      content
      author {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProjectMessage = /* GraphQL */ `
  subscription OnUpdateProjectMessage {
    onUpdateProjectMessage {
      id
      projectId
      userId
      content
      author {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProjectMessage = /* GraphQL */ `
  subscription OnDeleteProjectMessage {
    onDeleteProjectMessage {
      id
      projectId
      userId
      content
      author {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDocument = /* GraphQL */ `
  subscription OnCreateDocument($owner: String) {
    onCreateDocument(owner: $owner) {
      id
      name
      size
      contentType
      S3Key
      projectId
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      folderId
      folderUri
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDocument = /* GraphQL */ `
  subscription OnUpdateDocument($owner: String) {
    onUpdateDocument(owner: $owner) {
      id
      name
      size
      contentType
      S3Key
      projectId
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      folderId
      folderUri
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDocument = /* GraphQL */ `
  subscription OnDeleteDocument($owner: String) {
    onDeleteDocument(owner: $owner) {
      id
      name
      size
      contentType
      S3Key
      projectId
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      folderId
      folderUri
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDocumentFolder = /* GraphQL */ `
  subscription OnCreateDocumentFolder {
    onCreateDocumentFolder {
      id
      uri
      countDocuments
      documents {
        items {
          id
          name
          size
          contentType
          S3Key
          projectId
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          folderId
          folderUri
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDocumentFolder = /* GraphQL */ `
  subscription OnUpdateDocumentFolder {
    onUpdateDocumentFolder {
      id
      uri
      countDocuments
      documents {
        items {
          id
          name
          size
          contentType
          S3Key
          projectId
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          folderId
          folderUri
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDocumentFolder = /* GraphQL */ `
  subscription OnDeleteDocumentFolder {
    onDeleteDocumentFolder {
      id
      uri
      countDocuments
      documents {
        items {
          id
          name
          size
          contentType
          S3Key
          projectId
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          folderId
          folderUri
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRfq = /* GraphQL */ `
  subscription OnCreateRfq($owner: String) {
    onCreateRFQ(owner: $owner) {
      id
      name
      projectId
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      productFamily
      description
      version
      parentId
      values
      status
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      projectMemberIds
      sizingType
      revisions {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isRevision
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRfq = /* GraphQL */ `
  subscription OnUpdateRfq($owner: String) {
    onUpdateRFQ(owner: $owner) {
      id
      name
      projectId
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      productFamily
      description
      version
      parentId
      values
      status
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      projectMemberIds
      sizingType
      revisions {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isRevision
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRfq = /* GraphQL */ `
  subscription OnDeleteRfq($owner: String) {
    onDeleteRFQ(owner: $owner) {
      id
      name
      projectId
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      productFamily
      description
      version
      parentId
      values
      status
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      projectMemberIds
      sizingType
      revisions {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isRevision
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChannel = /* GraphQL */ `
  subscription OnCreateChannel {
    onCreateChannel {
      id
      name
      projectId
      roomId
      members {
        items {
          id
          channelId
          userId
          projectId
          channel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChannel = /* GraphQL */ `
  subscription OnUpdateChannel {
    onUpdateChannel {
      id
      name
      projectId
      roomId
      members {
        items {
          id
          channelId
          userId
          projectId
          channel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChannel = /* GraphQL */ `
  subscription OnDeleteChannel {
    onDeleteChannel {
      id
      name
      projectId
      roomId
      members {
        items {
          id
          channelId
          userId
          projectId
          channel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChannelMember = /* GraphQL */ `
  subscription OnCreateChannelMember {
    onCreateChannelMember {
      id
      channelId
      userId
      projectId
      channel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChannelMember = /* GraphQL */ `
  subscription OnUpdateChannelMember {
    onUpdateChannelMember {
      id
      channelId
      userId
      projectId
      channel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChannelMember = /* GraphQL */ `
  subscription OnDeleteChannelMember {
    onDeleteChannelMember {
      id
      channelId
      userId
      projectId
      channel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateActivity = /* GraphQL */ `
  subscription OnCreateActivity {
    onCreateActivity {
      id
      actor
      verb
      object
      target
      params
      activityFeedId
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateActivity = /* GraphQL */ `
  subscription OnUpdateActivity {
    onUpdateActivity {
      id
      actor
      verb
      object
      target
      params
      activityFeedId
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteActivity = /* GraphQL */ `
  subscription OnDeleteActivity {
    onDeleteActivity {
      id
      actor
      verb
      object
      target
      params
      activityFeedId
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateActivityFeed = /* GraphQL */ `
  subscription OnCreateActivityFeed {
    onCreateActivityFeed {
      id
      uri
      activities {
        items {
          id
          actor
          verb
          object
          target
          params
          activityFeedId
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateActivityFeed = /* GraphQL */ `
  subscription OnUpdateActivityFeed {
    onUpdateActivityFeed {
      id
      uri
      activities {
        items {
          id
          actor
          verb
          object
          target
          params
          activityFeedId
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteActivityFeed = /* GraphQL */ `
  subscription OnDeleteActivityFeed {
    onDeleteActivityFeed {
      id
      uri
      activities {
        items {
          id
          actor
          verb
          object
          target
          params
          activityFeedId
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateActivityFeedSubscription = /* GraphQL */ `
  subscription OnCreateActivityFeedSubscription {
    onCreateActivityFeedSubscription {
      id
      userId
      activityFeedId
      feed {
        id
        uri
        activities {
          items {
            id
            actor
            verb
            object
            target
            params
            activityFeedId
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateActivityFeedSubscription = /* GraphQL */ `
  subscription OnUpdateActivityFeedSubscription {
    onUpdateActivityFeedSubscription {
      id
      userId
      activityFeedId
      feed {
        id
        uri
        activities {
          items {
            id
            actor
            verb
            object
            target
            params
            activityFeedId
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteActivityFeedSubscription = /* GraphQL */ `
  subscription OnDeleteActivityFeedSubscription {
    onDeleteActivityFeedSubscription {
      id
      userId
      activityFeedId
      feed {
        id
        uri
        activities {
          items {
            id
            actor
            verb
            object
            target
            params
            activityFeedId
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
