import { Storage } from 'aws-amplify';
import React from 'react';
import { FileIcon, defaultStyles as fileIconDefaultStyles, DefaultExtensionType } from 'react-file-icon';

// import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

import { formatDate } from '../../../helpers';

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             width: '100%',
//             // maxWidth: 360,
//             // backgroundColor: theme.palette.background.paper,
//         },
//     }),
// );

const ITEM_HEIGHT = 48;

type Props = {
    item: any;
    dense?: boolean;
    onDeleteDocument: (doc: any) => void;
};
export default function DocumentFolderItem({ item, dense = true, onDeleteDocument }: Props) {
    const labelId = `checkbox-list-label-${item.id}`;

    const extension = item.name.split('.').pop(); //contentType.split('/')[1]
    const fileType = extension === 'pdf' ? 'acrobat' : `document`;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClickOptions = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownload = () => {
        handleClose();
        console.log(item.S3Key);
        Storage.get(item.S3Key, { expires: 60 })
            .then((result) => {
                window.open(String(result), '_blank');
                console.log(result);
            })
            .catch((err) => console.log(err));
    };
    const handleDelete = () => {
        handleClose();
        onDeleteDocument(item);
    };

    const ownerName = item.creator.fullName;
    const uploadDateString = formatDate(item.createdAt, 'LLL');
    const secondary = `Uploaded by ${ownerName} on ${uploadDateString}`;
    return (
        <ListItem key={item.id} role={undefined} dense={!!dense}>
            <ListItemIcon>
                <div style={{ width: 24 }}>
                    <FileIcon
                        extension={extension}
                        {...fileIconDefaultStyles[extension as DefaultExtensionType]}
                        type={fileType}
                    />
                </div>
            </ListItemIcon>
            <ListItemText id={labelId} primary={`${item.name}`} secondary={secondary} />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="options" onClick={handleClickOptions}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    }}
                >
                    <MenuItem onClick={handleDownload}>Download</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </Menu>

                {/* <DeleteConfirmDialog onAgree={handleDelete} /> */}
            </ListItemSecondaryAction>
        </ListItem>
    );
}

// type DeleteConfirmDialogProps = {
//     onAgree: any;
//     open: boolean;
// };

// function DeleteConfirmDialog({ onAgree, open }: DeleteConfirmDialogProps) {
//     const [open, setOpen] = React.useState(false);

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };
//     const handleAgree = () => {
//         // handleClose();
//         onAgree();
//     };

//     return (
//         <Dialog
//             open={open}
//             onClose={handleClose}
//             aria-labelledby="alert-dialog-title"
//             aria-describedby="alert-dialog-description"
//         >
//             <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
//             <DialogContent>
//                 <DialogContentText id="alert-dialog-description">Confirm Document deletion ?</DialogContentText>
//             </DialogContent>
//             <DialogActions>
//                 <Button onClick={handleClose} color="primary">
//                     Disagree
//                 </Button>
//                 <Button onClick={handleAgree} color="primary" autoFocus>
//                     Agree
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     );
// }
