import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const NavBar = styled.div`
    background: #001c31;
`;

export const SidebarUser = styled.div`
    background: black;
    width: 100%;
    padding: 16px;
    color: #999;
    /* position: fixed; */
    /* bottom: 0; */
    a,
    a:hover {
        color: #999;
        text-decoration: none;
    }
`;
export const SidebarUserName = styled.div`
    font-size: 16px;
`;
export const SidebarUserCompanyName = styled.div`
    font-size: 12px;
`;
interface NavBarProjectItemProps {
    readonly isCurrent: boolean;
    theme: any;
}
export const NavBarProjectItem = styled.div<NavBarProjectItemProps>`
    padding: 6px 16px;
    font-size: 16px;
    /* '#344568' */
    background-color: ${(props) => (props.isCurrent ? props?.theme?.palette?.primary?.main : '#transparent')};
    a {
        color: #fff;
        text-decoration: none;
        font-weight: ${(props) => (props.isCurrent ? 'bold' : 'normal')};
    }
`;
export const NavBarProjectItemAvatar = styled.div`
    width: 16px;
    display: inline-block;
    margin-left: 16px;
`;
export const NavBarProjectItemName = styled.div`
    width: 16px;
    display: inline-block;
    margin-left: 16px;
`;

export const NavBarChannel = styled.div`
    padding: 6px 16px;

    a {
        color: #9b9b9b;
        text-decoration: none;
        font-size: 14px;
        position: relative;
        display: block;
        padding-left: 16px;
        :before {
            display: block;
            position: absolute;
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 5px;
            top: 6px;
            left: 0px;
            background-color: #26a26b; //${(props) => props?.theme?.palette?.primary?.main};
        }
    }
`;

export const Document = styled.div`
    border-radius: 3px;
    height: 200px;
    width: 33%;
    float: left;
    text-align: center;
`;
// export const FileIcon = styled()

export const DocumentGrid = styled.div`
    width: 100%;
`;

// https://stackoverflow.com/questions/5533636/add-horizontal-scrollbar-to-html-table
export const ScrollableTable = styled.table`
    display: inline-block;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
    border: 0;
    width: 200%;
`;

interface ProductNameProps {
    theme: any;
}
export const ProductName = styled.h3<ProductNameProps>`
    position: relative;
    padding-bottom: 8px;
    font-size: 18px;

    :after {
        position: absolute;
        content: '';
        width: 150px;
        left: 0;
        bottom: 0;
        height: 5px;
        background: ${(props) => props?.theme?.palette?.primary?.main};
    }
`;

export const RfqFieldset = styled.fieldset`
    border: 0;
    margin-bottom: 30px;
    border-top: 1px dashed #666;
    padding-top: 30px;

    .MuiInputAdornment-root   p {
        font-size: .8rem;
    }
    .ptnv-autocalc {
        input,
        .MuiInputAdornment-root   p {
                /* color: #50E3C2 !important; */
                color: #999 !important;
            }
        }
    }

    .MuiTableRow-root.active {
        /* td { */
            background-color: rgba(80, 223, 187, .05);
        /* } */
        td {
            border-color: rgba(80, 223, 187, .8);
        }

        /* quantity */
        td:last-child { 
            input {
                color: #50E3C2 !important;
            }
        }  
    }
    .MuiTableRow-root {
        td:last-child { 
            input {
                font-weight: bold;
            }
        }  


    }


    textarea {
            border: 0px;
            background: transparent;
            outline: none;
            font-family: ${(props) => props?.theme?.typography?.fontFamily}
        }

    .MuiTableContainer-root {
        max-width: 1280px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
    }


    .product-cards-container {
        max-width: 1280px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        padding: 20px;
        margin-left: -20px;
    }
    .MuiCard-root {
        display: inline-block;
        margin-right: 50px;
        border-radius: 10px;

        .MuiCardContent-root {
            padding:0;

        }

        /* h3 {
            font-size: 11px;
            font-weight: 200;
            letter-spacing: 1.5px;
            color: #5E5E5E;
            text-transform: uppercase;
        } */
        .MuiTypography-overline
        {
            letter-spacing: 1.5px;

        }

        fieldset {
            outline: none;
            border:0;
            border-bottom: 2px solid #f1f1f1;
            padding: 10px 16px 20px;
            margin: 0;
            white-space: normal;

            /* &:last-of-type {
                border:0;
            } */

            label {
                white-space: normal;
                line-height: 1em;
                font-size: 13px;
                color: #333;
                font-weight: bold;
                text-align: right;
                width: 100%;
            }
            textarea {
                width: 100%;
            }
        }
    }
    .MuiPaper-root {
        border:3px solid #fff;
    }
    .MuiPaper-outlined {
        border:3px solid #50E3C2;
        box-shadow: 0 2px 15px rgba(80, 227, 194, .15)
    }
    .docfolder-container {
        margin-top: 8px;
        border:0;
        padding:0;
        ul {
            height: 150px;
            overflow: auto;
        } 
    }
`;

export const AddProductButton = styled(Button)`
    text-transform: none;
    font-size: 16px;
    padding: 8px 32px;
    width: 100%;
    height: 100%;

    .MuiButton-label {
        flex-direction: column;
    }
`;

export const AddProductContainer = styled.div`
    /* display: flex; */
    /* justify-content: center; */
    /* margin: 10px 0; */
    margin-bottom: 32px;
`;
