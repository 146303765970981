import styled from 'styled-components';

export const DocumentFolder = styled.div`
    border: 0;
    text-align: left;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    .transfers {
        svg {
            width: 16px;
            height: 16px;
        }
    }
    legend {
        /* color: #fff; */
        /* font-weight: bold; */
        font-size: 1rem;
        svg {
            width: 16px;
            height: 16px;
        }
    }

    ul {
        max-height: 150px;
        overflow-y: scroll;

        // li {
        //     border-bottom: 1px solid #ccc;
        // }
    }

    p {
        color: #999;
        font-size: 11px;
        white-space: normal;
        line-height: 1em;
    }
`;
