import * as React from 'react';
import useCurrentUser from 'src/hooks/useCurrentUser';
// import { ChannelWebview } from './ChannelWebview';
import styled from 'styled-components';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { ChevronDownIcon } from '@heroicons/react/solid';
// import { MinusSmIcon } from '@heroicons/react/solid';
import useProjects from 'src/hooks/useProjects';
import ChatIcon from '@material-ui/icons/Chat';
import Badge from '@material-ui/core/Badge';
import { Trans, useTranslation } from 'react-i18next';
import ProjectInbox from './ProjectInbox';

const StyledPopin = styled.div<{ opened: boolean }>`
    position: fixed;
    bottom: 0;
    height: ${(props) => (props.opened ? `calc(100vh - 80px)` : `calc(45px)`)};
    width: 360px;
    max-width: 100vw;
    max-height: 100vh;
    right: 0;
    border: 1px solid;
    border-color: ${(props) => (!props.opened ? '#ccc' : '#ccc')};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #fff;
    transition: 300ms;
    z-index: 1000;
    box-shadow: ${(props) => (!props.opened ? '-3px -5px 20px rgb(0 0 0 / 30%)' : 'none')};

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 45px;
        padding: 8px 10px;
        border-bottom: 1px solid #eee;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: ${(props) => (!props.opened ? props.theme.palette.primary.main : '#eee')};
        box-sizing: border-box;
        font-size: 13px;
        color: ${(props) => (props.opened ? props.theme.palette.primary.main : '#fff')};

        svg {
            color: ${(props) => (props.opened ? props.theme.palette.primary.main : '#fff')};
        }
    }

    iframe {
        width: 100%;
        border: 0;
        height: calc(100% - 45px);
    }

    .docfolder-container {
        max-height: 150px;
        overflow: auto;
    }
`;

const SideInbox = ({ open = false }: { open?: boolean }) => {
    const { currentUser } = useCurrentUser();
    const { project } = useProjects();

    const [opened, setIsOpened] = React.useState(open);
    const [openedOnce, setOpenedOnce] = React.useState(false);

    const toggle = React.useCallback(() => {
        setIsOpened(!opened);
    }, [opened]);

    React.useEffect(() => {
        if (opened && !openedOnce) {
            setOpenedOnce(true);
        }
    }, [opened, openedOnce]);

    const ToggleIcon = opened ? ChevronDownIcon : ChevronUpIcon;
    const channelId =
        project && project.channels?.items.length > 0 ? project.channels.items[0].id : currentUser.directChannelId;
    // React.useEffect(() => {
    //     console.log('changed project', project, channelId);
    // }, [project]);

    React.useEffect(() => {
        window.addEventListener(
            'message',
            (event) => {
                if (event.data === 'maximizeChat') setIsOpened(true);
            },
            false,
        );
    }, []);

    return (
        <>
            {project && (
                <StyledPopin opened={opened}>
                    <header onClick={toggle} style={{ cursor: 'pointer' }}>
                        {/* <Badge color="secondary" variant="dot"> */}
                        <ChatIcon />
                        {/* </Badge> */}
                        <h4>
                            Conversations{' '}
                            {project ? (
                                <span>
                                    pour le Job <b>{project.name}</b>
                                </span>
                            ) : (
                                `générales`
                            )}
                        </h4>
                        <ToggleIcon style={{ height: 32, width: 32, cursor: 'pointer' }} />
                    </header>
                    {/* {openedOnce && <ChannelWebview channelId={channelId} />} */}
                    {openedOnce && <ProjectInbox />}
                </StyledPopin>
            )}
        </>
    );
};
export default SideInbox;
