/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createChatChannel = /* GraphQL */ `
  mutation CreateChatChannel($users: [String], $projectId: String) {
    createChatChannel(users: $users, projectId: $projectId)
  }
`;
export const createChatUser = /* GraphQL */ `
  mutation CreateChatUser($userId: String, $email: String, $name: String) {
    createChatUser(userId: $userId, email: $email, name: $name)
  }
`;
export const updateChatUser = /* GraphQL */ `
  mutation UpdateChatUser($userId: String, $email: String, $name: String) {
    updateChatUser(userId: $userId, email: $email, name: $name)
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      owner
      username
      fullName
      firstName
      lastName
      mobilePhone
      role
      status
      company {
        name
        address
        country
        website
        phone
        duns
        siret
        countries
        productFamilies
      }
      jobTitle
      authUserId
      chat {
        userId
        loginToken
      }
      directChannelId
      directChannel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      activityFeeds {
        items {
          id
          userId
          activityFeedId
          feed {
            id
            uri
            activities {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      owner
      username
      fullName
      firstName
      lastName
      mobilePhone
      role
      status
      company {
        name
        address
        country
        website
        phone
        duns
        siret
        countries
        productFamilies
      }
      jobTitle
      authUserId
      chat {
        userId
        loginToken
      }
      directChannelId
      directChannel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      activityFeeds {
        items {
          id
          userId
          activityFeedId
          feed {
            id
            uri
            activities {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      owner
      username
      fullName
      firstName
      lastName
      mobilePhone
      role
      status
      company {
        name
        address
        country
        website
        phone
        duns
        siret
        countries
        productFamilies
      }
      jobTitle
      authUserId
      chat {
        userId
        loginToken
      }
      directChannelId
      directChannel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      activityFeeds {
        items {
          id
          userId
          activityFeedId
          feed {
            id
            uri
            activities {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      pitch
      status
      prettyName
      productFamilies
      country
      incoterm
      shippingAddress
      structureType
      targetDeliveryDate
      members {
        items {
          id
          projectId
          userId
          role
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      channels {
        items {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rfqs {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isUrgentDelivery
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      pitch
      status
      prettyName
      productFamilies
      country
      incoterm
      shippingAddress
      structureType
      targetDeliveryDate
      members {
        items {
          id
          projectId
          userId
          role
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      channels {
        items {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rfqs {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isUrgentDelivery
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      pitch
      status
      prettyName
      productFamilies
      country
      incoterm
      shippingAddress
      structureType
      targetDeliveryDate
      members {
        items {
          id
          projectId
          userId
          role
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      channels {
        items {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rfqs {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isUrgentDelivery
      createdAt
      updatedAt
    }
  }
`;
export const createProjectMember = /* GraphQL */ `
  mutation CreateProjectMember(
    $input: CreateProjectMemberInput!
    $condition: ModelProjectMemberConditionInput
  ) {
    createProjectMember(input: $input, condition: $condition) {
      id
      projectId
      userId
      role
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProjectMember = /* GraphQL */ `
  mutation UpdateProjectMember(
    $input: UpdateProjectMemberInput!
    $condition: ModelProjectMemberConditionInput
  ) {
    updateProjectMember(input: $input, condition: $condition) {
      id
      projectId
      userId
      role
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProjectMember = /* GraphQL */ `
  mutation DeleteProjectMember(
    $input: DeleteProjectMemberInput!
    $condition: ModelProjectMemberConditionInput
  ) {
    deleteProjectMember(input: $input, condition: $condition) {
      id
      projectId
      userId
      role
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProjectMessage = /* GraphQL */ `
  mutation CreateProjectMessage(
    $input: CreateProjectMessageInput!
    $condition: ModelProjectMessageConditionInput
  ) {
    createProjectMessage(input: $input, condition: $condition) {
      id
      projectId
      userId
      content
      author {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProjectMessage = /* GraphQL */ `
  mutation UpdateProjectMessage(
    $input: UpdateProjectMessageInput!
    $condition: ModelProjectMessageConditionInput
  ) {
    updateProjectMessage(input: $input, condition: $condition) {
      id
      projectId
      userId
      content
      author {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProjectMessage = /* GraphQL */ `
  mutation DeleteProjectMessage(
    $input: DeleteProjectMessageInput!
    $condition: ModelProjectMessageConditionInput
  ) {
    deleteProjectMessage(input: $input, condition: $condition) {
      id
      projectId
      userId
      content
      author {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      name
      size
      contentType
      S3Key
      projectId
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      folderId
      folderUri
      createdAt
      updatedAt
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      name
      size
      contentType
      S3Key
      projectId
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      folderId
      folderUri
      createdAt
      updatedAt
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      name
      size
      contentType
      S3Key
      projectId
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      folderId
      folderUri
      createdAt
      updatedAt
    }
  }
`;
export const createDocumentFolder = /* GraphQL */ `
  mutation CreateDocumentFolder(
    $input: CreateDocumentFolderInput!
    $condition: ModelDocumentFolderConditionInput
  ) {
    createDocumentFolder(input: $input, condition: $condition) {
      id
      uri
      countDocuments
      documents {
        items {
          id
          name
          size
          contentType
          S3Key
          projectId
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          folderId
          folderUri
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDocumentFolder = /* GraphQL */ `
  mutation UpdateDocumentFolder(
    $input: UpdateDocumentFolderInput!
    $condition: ModelDocumentFolderConditionInput
  ) {
    updateDocumentFolder(input: $input, condition: $condition) {
      id
      uri
      countDocuments
      documents {
        items {
          id
          name
          size
          contentType
          S3Key
          projectId
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          folderId
          folderUri
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDocumentFolder = /* GraphQL */ `
  mutation DeleteDocumentFolder(
    $input: DeleteDocumentFolderInput!
    $condition: ModelDocumentFolderConditionInput
  ) {
    deleteDocumentFolder(input: $input, condition: $condition) {
      id
      uri
      countDocuments
      documents {
        items {
          id
          name
          size
          contentType
          S3Key
          projectId
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          folderId
          folderUri
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRfq = /* GraphQL */ `
  mutation CreateRfq(
    $input: CreateRFQInput!
    $condition: ModelRFQConditionInput
  ) {
    createRFQ(input: $input, condition: $condition) {
      id
      name
      projectId
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      productFamily
      description
      version
      parentId
      values
      status
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      projectMemberIds
      sizingType
      revisions {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isRevision
      createdAt
      updatedAt
    }
  }
`;
export const updateRfq = /* GraphQL */ `
  mutation UpdateRfq(
    $input: UpdateRFQInput!
    $condition: ModelRFQConditionInput
  ) {
    updateRFQ(input: $input, condition: $condition) {
      id
      name
      projectId
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      productFamily
      description
      version
      parentId
      values
      status
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      projectMemberIds
      sizingType
      revisions {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isRevision
      createdAt
      updatedAt
    }
  }
`;
export const deleteRfq = /* GraphQL */ `
  mutation DeleteRfq(
    $input: DeleteRFQInput!
    $condition: ModelRFQConditionInput
  ) {
    deleteRFQ(input: $input, condition: $condition) {
      id
      name
      projectId
      project {
        id
        name
        pitch
        status
        prettyName
        productFamilies
        country
        incoterm
        shippingAddress
        structureType
        targetDeliveryDate
        members {
          items {
            id
            projectId
            userId
            role
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        creatorId
        creator {
          id
          email
          owner
          username
          fullName
          firstName
          lastName
          mobilePhone
          role
          status
          company {
            name
            address
            country
            website
            phone
            duns
            siret
            countries
            productFamilies
          }
          jobTitle
          authUserId
          chat {
            userId
            loginToken
          }
          directChannelId
          directChannel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          activityFeeds {
            items {
              id
              userId
              activityFeedId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        channels {
          items {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        rfqs {
          items {
            id
            name
            projectId
            project {
              id
              name
              pitch
              status
              prettyName
              productFamilies
              country
              incoterm
              shippingAddress
              structureType
              targetDeliveryDate
              owner
              creatorId
              isUrgentDelivery
              createdAt
              updatedAt
            }
            productFamily
            description
            version
            parentId
            values
            status
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            projectMemberIds
            sizingType
            revisions {
              nextToken
            }
            isRevision
            createdAt
            updatedAt
          }
          nextToken
        }
        isUrgentDelivery
        createdAt
        updatedAt
      }
      productFamily
      description
      version
      parentId
      values
      status
      owner
      creatorId
      creator {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      projectMemberIds
      sizingType
      revisions {
        items {
          id
          name
          projectId
          project {
            id
            name
            pitch
            status
            prettyName
            productFamilies
            country
            incoterm
            shippingAddress
            structureType
            targetDeliveryDate
            members {
              nextToken
            }
            owner
            creatorId
            creator {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            channels {
              nextToken
            }
            rfqs {
              nextToken
            }
            isUrgentDelivery
            createdAt
            updatedAt
          }
          productFamily
          description
          version
          parentId
          values
          status
          owner
          creatorId
          creator {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          projectMemberIds
          sizingType
          revisions {
            items {
              id
              name
              projectId
              productFamily
              description
              version
              parentId
              values
              status
              owner
              creatorId
              projectMemberIds
              sizingType
              isRevision
              createdAt
              updatedAt
            }
            nextToken
          }
          isRevision
          createdAt
          updatedAt
        }
        nextToken
      }
      isRevision
      createdAt
      updatedAt
    }
  }
`;
export const createChannel = /* GraphQL */ `
  mutation CreateChannel(
    $input: CreateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    createChannel(input: $input, condition: $condition) {
      id
      name
      projectId
      roomId
      members {
        items {
          id
          channelId
          userId
          projectId
          channel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChannel = /* GraphQL */ `
  mutation UpdateChannel(
    $input: UpdateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    updateChannel(input: $input, condition: $condition) {
      id
      name
      projectId
      roomId
      members {
        items {
          id
          channelId
          userId
          projectId
          channel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChannel = /* GraphQL */ `
  mutation DeleteChannel(
    $input: DeleteChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    deleteChannel(input: $input, condition: $condition) {
      id
      name
      projectId
      roomId
      members {
        items {
          id
          channelId
          userId
          projectId
          channel {
            id
            name
            projectId
            roomId
            members {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChannelMember = /* GraphQL */ `
  mutation CreateChannelMember(
    $input: CreateChannelMemberInput!
    $condition: ModelChannelMemberConditionInput
  ) {
    createChannelMember(input: $input, condition: $condition) {
      id
      channelId
      userId
      projectId
      channel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChannelMember = /* GraphQL */ `
  mutation UpdateChannelMember(
    $input: UpdateChannelMemberInput!
    $condition: ModelChannelMemberConditionInput
  ) {
    updateChannelMember(input: $input, condition: $condition) {
      id
      channelId
      userId
      projectId
      channel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChannelMember = /* GraphQL */ `
  mutation DeleteChannelMember(
    $input: DeleteChannelMemberInput!
    $condition: ModelChannelMemberConditionInput
  ) {
    deleteChannelMember(input: $input, condition: $condition) {
      id
      channelId
      userId
      projectId
      channel {
        id
        name
        projectId
        roomId
        members {
          items {
            id
            channelId
            userId
            projectId
            channel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      actor
      verb
      object
      target
      params
      activityFeedId
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      actor
      verb
      object
      target
      params
      activityFeedId
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      actor
      verb
      object
      target
      params
      activityFeedId
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createActivityFeed = /* GraphQL */ `
  mutation CreateActivityFeed(
    $input: CreateActivityFeedInput!
    $condition: ModelActivityFeedConditionInput
  ) {
    createActivityFeed(input: $input, condition: $condition) {
      id
      uri
      activities {
        items {
          id
          actor
          verb
          object
          target
          params
          activityFeedId
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateActivityFeed = /* GraphQL */ `
  mutation UpdateActivityFeed(
    $input: UpdateActivityFeedInput!
    $condition: ModelActivityFeedConditionInput
  ) {
    updateActivityFeed(input: $input, condition: $condition) {
      id
      uri
      activities {
        items {
          id
          actor
          verb
          object
          target
          params
          activityFeedId
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteActivityFeed = /* GraphQL */ `
  mutation DeleteActivityFeed(
    $input: DeleteActivityFeedInput!
    $condition: ModelActivityFeedConditionInput
  ) {
    deleteActivityFeed(input: $input, condition: $condition) {
      id
      uri
      activities {
        items {
          id
          actor
          verb
          object
          target
          params
          activityFeedId
          user {
            id
            email
            owner
            username
            fullName
            firstName
            lastName
            mobilePhone
            role
            status
            company {
              name
              address
              country
              website
              phone
              duns
              siret
              countries
              productFamilies
            }
            jobTitle
            authUserId
            chat {
              userId
              loginToken
            }
            directChannelId
            directChannel {
              id
              name
              projectId
              roomId
              createdAt
              updatedAt
            }
            activityFeeds {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createActivityFeedSubscription = /* GraphQL */ `
  mutation CreateActivityFeedSubscription(
    $input: CreateActivityFeedSubscriptionInput!
    $condition: ModelActivityFeedSubscriptionConditionInput
  ) {
    createActivityFeedSubscription(input: $input, condition: $condition) {
      id
      userId
      activityFeedId
      feed {
        id
        uri
        activities {
          items {
            id
            actor
            verb
            object
            target
            params
            activityFeedId
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateActivityFeedSubscription = /* GraphQL */ `
  mutation UpdateActivityFeedSubscription(
    $input: UpdateActivityFeedSubscriptionInput!
    $condition: ModelActivityFeedSubscriptionConditionInput
  ) {
    updateActivityFeedSubscription(input: $input, condition: $condition) {
      id
      userId
      activityFeedId
      feed {
        id
        uri
        activities {
          items {
            id
            actor
            verb
            object
            target
            params
            activityFeedId
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteActivityFeedSubscription = /* GraphQL */ `
  mutation DeleteActivityFeedSubscription(
    $input: DeleteActivityFeedSubscriptionInput!
    $condition: ModelActivityFeedSubscriptionConditionInput
  ) {
    deleteActivityFeedSubscription(input: $input, condition: $condition) {
      id
      userId
      activityFeedId
      feed {
        id
        uri
        activities {
          items {
            id
            actor
            verb
            object
            target
            params
            activityFeedId
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        owner
        username
        fullName
        firstName
        lastName
        mobilePhone
        role
        status
        company {
          name
          address
          country
          website
          phone
          duns
          siret
          countries
          productFamilies
        }
        jobTitle
        authUserId
        chat {
          userId
          loginToken
        }
        directChannelId
        directChannel {
          id
          name
          projectId
          roomId
          members {
            items {
              id
              channelId
              userId
              projectId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        activityFeeds {
          items {
            id
            userId
            activityFeedId
            feed {
              id
              uri
              createdAt
              updatedAt
            }
            user {
              id
              email
              owner
              username
              fullName
              firstName
              lastName
              mobilePhone
              role
              status
              jobTitle
              authUserId
              directChannelId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
