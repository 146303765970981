import React from 'react';
import './App.css';
import '@vetixy/circular-std';
// import { withAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CustomerApp } from './components/CustomerApp';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Trans, useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { AmplifyAuthenticator, AmplifyAuthContainer, AmplifySignIn, AmplifySignUp } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider } from 'react-query';
import { CubeTransparentIcon } from '@heroicons/react/solid';

import { ProjectsProvider } from './contexts/Projects';
import { CurrentUserProvider } from './contexts/CurrentUser';
import ScrollToTop from 'src/core/components/ScrollToTop';
import { Typography } from '@material-ui/core';

const queryClient = new QueryClient();

function App() {
    const [authState, setAuthState] = React.useState<AuthState>();
    const [user, setUser] = React.useState<any | undefined>();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData);
        });
    }, []);

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const { t } = useTranslation();

    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    // type: prefersDarkMode ? 'dark' : 'light',
                    type: 'light',
                    primary: {
                        main: '#2029f7',
                    },
                    secondary: {
                        main: '#26d1cb',
                    },
                    background: {
                        default: '#fff',
                    },
                },
                typography: {
                    fontFamily: 'CircularStd, Arial',
                    button: {
                        textTransform: 'none',
                    },
                },
                overrides: {
                    // Style sheet name ⚛️
                    MuiButton: {
                        // Name of the rule
                        text: {
                            // Some CSS
                            color: 'white',
                            // textTransform: 'none',
                        },
                    },
                    MuiAppBar: {
                        colorPrimary: {
                            backgroundColor: '#1d1d56',
                        },
                    },
                    MuiTooltip: {
                        tooltip: {
                            fontSize: 16,
                        },
                    },
                    // MuiTextField: {
                    //     root: {
                    //         borderRadius: 4,
                    //         backgroundColor: 'transparent',
                    //         border: '1px solid #666666',
                    //         fontSize: 16,
                    //         padding: '10px 12px',
                    //         // width: 'calc(100% - 24px)',
                    //     },
                    //     MuiInput: {

                    //     }
                    // },
                },
            }),
        [prefersDarkMode],
    );

    return authState === AuthState.SignedIn && user ? (
        <Router>
            <ScrollToTop />
            <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                    <CurrentUserProvider>
                        <ProjectsProvider>
                            <CssBaseline />
                            <QueryClientProvider client={queryClient}>
                                <CustomerApp />
                            </QueryClientProvider>
                        </ProjectsProvider>
                    </CurrentUserProvider>
                </ThemeProvider>
            </MuiThemeProvider>
        </Router>
    ) : (
        <AmplifyAuthContainer>
            <div
                style={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    top: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <CubeTransparentIcon style={{ width: 60, height: 60 }} />
                <h1 style={{ margin: 0, marginTop: 0 }}>Pontenovo</h1>
                <Typography style={{ margin: 0, marginTop: 0, width: 400, textAlign: 'center' }}>
                    Plateforme de commande d’appuis en néoprène pour le génie civil et le bâtiment
                </Typography>
            </div>
            <AmplifyAuthenticator>
                <AmplifySignIn headerText={t('Log in')} federated={undefined} slot="sign-in" usernameAlias="email" />
                <AmplifySignUp
                    headerText={t('Create a Pontenovo account')}
                    slot="sign-up"
                    usernameAlias="email"
                    formFields={[{ type: 'email' }, { type: 'password' }]}
                />
            </AmplifyAuthenticator>
        </AmplifyAuthContainer>
    );
}
// export default withAuthenticator(App, { usernameAlias: 'email', federated: {} });
// federated: {},
export default App;
