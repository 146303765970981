import React from 'react';
import Button from '@material-ui/core/Button';
import { USER_STATUS, USER_ROLES } from 'src/constants';
import { Role } from 'src/types';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { createMarketplaceUser, updateMarketplaceUser } from 'src/domains/marketplace/api/users';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

// const MainRolePick = () => {
//     const history = useHistory();

//     const { currentUser, authUser, setCurrentUser } = useCurrentUser();
//     const [role, setRole] = React.useState<unknown>(currentUser.role);

//     const onSubmit = async () => {
//         const updatedUserData = { role: role, status: USER_STATUS.ONBOARDED };
//         const newUser = await updateMarketplaceUser(updatedUserData, currentUser, authUser);
//         setCurrentUser(newUser);
//         // move to profile page
//         history.push('/profile', { submitLabel: 'Next', isOnboarding: true });
//     };

//     const changeRole = (role: Role) => {
//         setRole(role);
//     };

//     return (
//         <div>
//             I am a
//             <div>
//                 <Button
//                     variant="outlined"
//                     color={role === (Role.VENDOR as unknown) ? 'secondary' : 'default'}
//                     onClick={() => changeRole(Role.VENDOR)}
//                 >
//                     Vendor
//                 </Button>
//                 <Button
//                     variant="outlined"
//                     color={role === (Role.BUYER as unknown) ? 'secondary' : 'default'}
//                     onClick={() => changeRole(Role.BUYER)}
//                 >
//                     Buyer
//                 </Button>
//             </div>
//             <Button variant="contained" disabled={!!!role} color="primary" onClick={onSubmit}>
//                 Next
//             </Button>
//         </div>
//     );
// };
export const Welcome = () => {
    const { currentUser, authUser, setCurrentUser } = useCurrentUser();
    const history = useHistory();
    const { t } = useTranslation();

    const initUser = React.useCallback(async () => {
        console.log('init user with authUser', authUser);
        const currentUser = await createMarketplaceUser(authUser);
        // support only buyer in v1
        const role = Role.BUYER;
        const updatedUserData = { role: role, status: USER_STATUS.ONBOARDED };
        const newUser = await updateMarketplaceUser(updatedUserData, currentUser, authUser);
        setCurrentUser(newUser);
        setTimeout(() => ((window as any).location = '/profile'), 2000);
    }, [authUser]);

    // React.useEffect(() => {
    //     initUser();
    // }, [currentUser]);

    React.useEffect(() => {
        // console.log('current user', currentUser);
        // if (!!currentUser) {
        //     history.push('/profile');
        // } else {
        initUser();
        // }
    }, []);

    return (
        <div>
            <h3>
                <Trans t={t}>Welcome and thanks for joining Pontenovo !</Trans>
            </h3>
            {!!!currentUser && (
                <div>
                    <Trans t={t}>Initializing your account...</Trans>
                </div>
            )}
            {/* {!!currentUser && <MainRolePick />} */}
        </div>
    );
};
