import React from 'react';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import { ListUsersQuery } from '../API';
import { listUsers } from '../graphql/queries';
import { USER_ROLES } from 'src/constants';

type ContextValue = {
    currentUser: any | undefined | null;
    setCurrentUser: React.Dispatch<React.SetStateAction<any>>;
    authUser: any | undefined | null;
    isBuyer: boolean;
    isVendor: boolean;
    isBroker: boolean;
    isAdmin: boolean;
    fetchAuthUser: () => void;
    // setAuthUser: React.Dispatch<React.SetStateAction<any>>;
};

const CurrentUserContext = React.createContext<ContextValue>({} as ContextValue);
type Props = {
    children: React.ReactNode;
};
export const CurrentUserProvider = (props: Props) => {
    const [currentUser, setCurrentUser] = React.useState<any | null | undefined>(undefined);
    const [authUser, setAuthUser] = React.useState<any | null | undefined>(undefined);
    const [isBuyer, setIsBuyer] = React.useState<boolean>(false);
    const [isVendor, setIsVendor] = React.useState<boolean>(false);
    const [isBroker, setIsBroker] = React.useState<boolean>(false);
    const [isAdmin, setIsAdmin] = React.useState<boolean>(false);

    const checkOnboardedUser = React.useCallback(async () => {
        const user = (await API.graphql(
            graphqlOperation(listUsers, {
                filter: {
                    authUserId: { eq: authUser.username },
                },
            }),
        )) as {
            data: ListUsersQuery;
        };

        if (user.data?.listUsers?.items?.length === 0) {
            setCurrentUser(null);
            setAuthUser(null);
        } else {
            if (user.data?.listUsers?.items) {
                setCurrentUser(user.data.listUsers.items[0]);
            }
        }
    }, [authUser]);

    const fetchAuthUser = async () => {
        console.log('fetchAuthUser');
        const cognitoUser = await Auth.currentAuthenticatedUser();
        setAuthUser(cognitoUser);
        const admin = !!cognitoUser?.signInUserSession?.accessToken?.payload['cognito:groups']?.includes('Admins');
        setIsAdmin(admin);
        // setIsAdmin(true);
        console.log('cognitoUser', cognitoUser, admin);
    };

    React.useEffect(() => {
        if (authUser) checkOnboardedUser();
    }, [authUser, checkOnboardedUser]);

    React.useEffect(() => {
        fetchAuthUser();
    }, []);

    React.useEffect(() => {
        if (currentUser) {
            console.log('currentUser changed', currentUser);
            if (currentUser.role === USER_ROLES.BROKER) {
                setIsBroker(true);
            } else if (currentUser.role === USER_ROLES.BUYER) {
                setIsBuyer(true);
            } else if (currentUser.role === USER_ROLES.VENDOR) {
                setIsVendor(true);
            }
        }
    }, [currentUser]);

    return (
        <CurrentUserContext.Provider
            value={{ currentUser, setCurrentUser, authUser, isBuyer, isVendor, isBroker, isAdmin, fetchAuthUser }}
        >
            {props.children}
        </CurrentUserContext.Provider>
    );
};

export const CurrentUserConsumer = CurrentUserContext.Consumer;

export default CurrentUserContext;
