import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useForm, FormProvider } from 'react-hook-form';
import { API, graphqlOperation } from 'aws-amplify';
import { isEmpty, groupBy, find } from 'lodash';
import { FormControl, Switch } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { createRfq } from 'src/graphql/mutations';
import { CreateRfqMutation } from 'src/API';

import { getRfq } from '../../../../graphql/queries';
import * as Styled from './styles';
import {
    elastomericBearingCatalog,
    elastomericBearingConstraintsCatalog,
    elastomericBearingVolumesCatalog,
} from './catalog';
import { GetRfqQuery } from '../../../../API';
import { updateRfq } from 'src/graphql/mutations';
import { formatDate, getUUID } from '../../../../helpers';
import { Dispatcher, SizingType } from 'src/types';
import { ProductPreview } from './ProductPreview';
import ProjectName from '../ProjectName';
import Breadcrumbs from 'src/core/components/Breadcrumbs/Breadcrumbs';
import useProjects from 'src/hooks/useProjects';
import { ProductCardForm } from './ProductCardForm';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { RFQStatus } from '../RFQ/RFQStatus';
import { AddCircle, Block } from '@material-ui/icons';
import { RFQ_STATUS } from 'src/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(2),
            minWidth: 200,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        table: {
            minWidth: 700,
            marginBottom: 60,

            // '& .MuiTableCell-root': {
            //     borderLeft: '1px solid rgba(256 , 256, 256, .1)',
            // },
        },
    }),
);

const getProductTypeAttributes = (productDef: any, product: any) => {
    // check if fallback
    // if (product && product.isFallback === true) {
    //     return productDef.fallback.attributes;
    // }
    return productDef.attributes;
};

type Props = {
    rfq: any;
    editable: false;
    setVersion: Dispatcher<number>;
    setUpdatedAt: Dispatcher<string>;
};

const getNewProductId = (rfqId: string, uri: string, productIndex: number, itemIndex: number): string => {
    return `${rfqId}_${productIndex}_${itemIndex}`; //${getUUID()}`;
};

export const RFQFormContent = (props: Props) => {
    const { rfq, editable } = props;
    const rfqProducts = JSON.parse(rfq.values) || {};
    const [data, setData] = React.useState<any>({});
    const [catalog, setCatalog] = React.useState<Array<any>>([]);

    const { t } = useTranslation();

    const [changed, setChanged] = React.useState<boolean | null>(null);
    const productByCategory = groupBy(catalog, 'category');
    const classes = useStyles();
    const history = useHistory();
    const [currentProductEdit, setCurrentProductEdit] = React.useState<any | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<any | null>(null);
    const [canRequestQuote, setCanRequestQuote] = React.useState<boolean | null>(null);

    const methods = useForm<any>({
        defaultValues: rfqProducts,
    });
    const {
        getValues,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        control,
        setValue,
    } = methods;
    // const watchFields = watch((data, { name, type }) => {
    //     console.log(data, name, type)
    // });

    React.useEffect(() => {
        if (!catalog) return;
        const subscription = watch((values, { name, type }) => {
            console.log('watch form', values, name, type);
            if (!name) {
                setChanged(true);
                return;
            }
            setChanged(true);

            Object.entries(values).map(([uri, products]) => {
                // if (!Array.isArray(products)) return;
                // productsCount += (products as any[]).length;
                if (name.indexOf(`${uri}[`) < 0) return;
                console.log('product uri to recalc', name, uri);

                (products as any[]).map((product: any, index) => {
                    const productDef = catalog.find((x) => x.uri === uri);
                    console.log('productDef', productDef);
                    productDef?.attributes.map((attr: any) => {
                        // don't recalc if watching this exact field update (prevent infinite loop)
                        if (attr.calc) {
                            // && name.indexOf(`.${attr.uri}.`) < 0) {
                            const value = attr.calc(values[uri][index]);
                            console.log('recalc', values[uri][index], value);
                            if (+values[uri][index][attr.uri].value != value)
                                setValue(`${uri}[${index}].${attr.uri}.value`, value);
                        }
                    });
                });
            });
        });
        return () => subscription.unsubscribe();
    }, [watch, catalog]);

    const onSubmit = async (data: any) => {
        // disable save button
        setChanged(false);

        try {
            const values = getValues();

            console.log('onSubmit', data);

            // save revision
            const originalRfq = ((await API.graphql(graphqlOperation(getRfq, { id: rfq.id }))) as {
                data: GetRfqQuery;
            }).data.getRFQ;
            if (!originalRfq) return;

            if (!originalRfq?.revisions?.items?.find((x: any) => x.version === rfq.version)) {
                const { id, revisions, project, creator, createdAt, updatedAt, ...revisionInput } = originalRfq; // remove parent only fields
                const revisionResponse = (await API.graphql(
                    graphqlOperation(createRfq, {
                        input: { ...revisionInput, parentId: rfq.id, isRevision: true },
                    }),
                )) as {
                    data: CreateRfqMutation;
                };
            }
            // const revisionId = revisionResponse?.data?.createRFQ?.id;

            const newVersion = ++rfq.version;
            const updatedRfq = { id: rfq.id, version: newVersion, values: JSON.stringify(values) };
            const response = (await API.graphql(graphqlOperation(updateRfq, { input: updatedRfq }))) as any;
            props.setVersion(newVersion);
            props.setUpdatedAt(response.data.updateRFQ.updatedAt);
            setChanged(false);
        } finally {
            // re-enable save button
            setChanged(true);
        }
    };

    const onSubmitRFQ = React.useCallback(async () => {
        if (changed) await onSubmit(getValues());
        if (rfq.status === null || rfq.status === RFQ_STATUS.NEW) {
            await API.graphql(
                graphqlOperation(updateRfq, { input: { id: rfq.id, status: RFQ_STATUS.QUOTE_REQUESTED } }),
            );
        }
        history.push(`/rfq/${rfq.id}/view`);
    }, [changed, rfq]);

    const handleCancelRfq = React.useCallback(async () => {
        await API.graphql(graphqlOperation(updateRfq, { input: { id: rfq.id, status: RFQ_STATUS.CANCELLED } }));

        history.push(`/`);
    }, [rfq]);

    const addProduct = React.useCallback(
        (productDef: any) => {
            const product: any = {};
            const data = getValues();

            productDef.attributes.forEach((attr: any) => {
                product[attr.uri as string] = attr.default;
            });
            const newData = { ...data };
            console.log('data', newData);
            if (newData[productDef.uri]) {
                newData[productDef.uri] = [...newData[productDef.uri], product];
            } else {
                newData[productDef.uri] = [product];
            }
            console.log('adding', productDef.uri, newData);
            setData(newData);
            reset(newData);

            // scroll to section
            setTimeout(() => document?.getElementById(productDef!.uri)?.scrollIntoView(), 200);
        },
        [data],
    );

    const handleProductSpecEdit = (e: any, uri: string, product: any, id: string) => {
        e.stopPropagation();
        console.log('editing', uri, product);
        setCurrentProductEdit({ uri, product: { ...product, _id: id } });
    };

    // React.useEffect(() => {
    //     const values = getValues();
    //     console.log('watch', values, watchFields);

    //     // return;

    //     if (changed === null) {
    //         // first time
    //         setChanged(false);
    //     } else {
    //         setChanged(true);
    //     }
    //     // return;
    //     // setChanged(JSON.stringify(values) != JSON.stringify(data));
    //     // if (!isEmpty(values)) onSubmit(values);
    //     // let productsCount = 0;

    //     // todo : update only current product !!!
    //     Object.entries(data).map(([uri, products]) => {
    //         // if (!Array.isArray(products)) return;
    //         // productsCount += (products as any[]).length;

    //         (products as any[]).map((product: any, index) => {
    //             const productDef = catalog.find((x) => x.uri === uri);
    //             productDef?.attributes.map((attr: any) => {
    //                 if (attr.calc) {
    //                     const value = attr.calc(values[uri][index]);
    //                     console.log('calc', values[uri][index], value);
    //                     // if (+values[uri][index][attr.uri].value != value)
    //                     // setValue(`${uri}[${index}].${attr.uri}.value`, value);
    //                 }
    //             });
    //         });
    //     });

    //     // setCanRequestQuote(productsCount > 0);
    // }, [watchFields, catalog]);

    // watch((data, { name, type }) => {
    //     console.log(data, name, type);
    // });

    // React.useEffect(() => {
    //     if (!isEmpty(data)) onSubmit(data);
    // }, [data]);

    React.useEffect(() => {
        // reset(rfqProducts);
        setData(rfqProducts);
    }, []);

    React.useEffect(() => {
        let productsCount = 0;
        Object.entries(data).map(([uri, products]) => {
            productsCount += (products as any[]).length;
        });
        setCanRequestQuote(productsCount > 0);
    }, [data]);

    // const resetEdit = React.useCallback(() => {
    //     if (!!currentProductEdit) {
    //         console.log('reset');
    //         setCurrentProductEdit(null);
    //     }
    // }, [currentProductEdit]);

    const deleteProduct = React.useCallback(
        (isConfirmed: boolean) => {
            if (!isConfirmed) {
                return setShowDeleteDialog(null);
            }
            // (uri: string, index: number) => {
            const { uri, index } = showDeleteDialog;
            const values = getValues();
            values[uri].splice(index, 1);
            console.log('deleting product', uri, index, values);

            setData(values);
            reset(values);

            setShowDeleteDialog(null);
        },
        [showDeleteDialog],
    );

    React.useEffect(() => {
        switch (rfq.sizingType) {
            case 'VOLUMES':
                setCatalog(elastomericBearingVolumesCatalog);
                break;
            case 'CONSTRAINTS':
                setCatalog(elastomericBearingConstraintsCatalog);
                break;
            case 'DIMENSIONS':
            default:
                setCatalog(elastomericBearingCatalog);
        }
    }, [rfq.sizingType]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container style={{ justifyContent: 'flex-end', marginTop: -58, paddingRight: 10 }}>
                    <Grid item>
                        {[RFQ_STATUS.NEW, RFQ_STATUS.QUOTE_REQUESTED].includes(rfq.status) && (
                            <Button
                                onClick={handleCancelRfq}
                                startIcon={<Block />}
                                variant="outlined"
                                color="primary"
                                size="medium"
                            >
                                {t('Abort')}
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            type="submit"
                            disabled={changed !== true || !editable}
                            style={{ marginLeft: 10 }}
                        >
                            <Trans t={t}>Save changes</Trans>
                        </Button>
                    </Grid>
                </Grid>

                {showDeleteDialog && <DeleteProductAlertDialog onAgree={deleteProduct} open={!!showDeleteDialog} />}
                <div>
                    {
                        <div style={{ marginTop: 40, marginBottom: 64 }}>
                            {/* <FormControlLabel
                                control={
                                    <Switch
                                        disabled={!editable}
                                        name="isWithDimensions"
                                        checked={withDimensions}
                                        onChange={handleWithDimensionsChange}
                                    />
                                }
                                label={t('Je connais déjà les dimensions des appuis')}
                            /> */}

                            <Typography
                                variant="h6"
                                gutterBottom
                                style={{
                                    marginTop: 32,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: 10,
                                }}
                            >
                                <Trans t={t}>Product selection</Trans>
                                <img src="/images/ce-mark-official.svg" height="26" />
                            </Typography>
                            <Typography paragraph variant="body2">
                                Les appuis sont réalisés à partir de feuillets d'élastomère et de frettes en acier
                                vulcanisées ensemble.
                                <br />
                                Cette association de matériaux forme une pièce résistante à la compression et à la
                                torsion.
                                <br />
                                L'élastomère permet d'assurer la protection contre les actions environnementales, les
                                conditions météorologiques et en particulier contre la concentration d'ozone et les
                                rayons ultraviolets.
                                <br />
                                L'élastomère est composée de feuillets de chloroprène (CR).
                                <br />
                                L'acier est de nuance S235 JRG2.
                            </Typography>

                            {Object.entries(productByCategory).map(([category, products]) => {
                                return (
                                    <Styled.AddProductContainer key={category}>
                                        {/* <h3>Add {category}</h3> */}
                                        <Grid container spacing={2} style={{ padding: 30 }}>
                                            {products.map((product: any) => (
                                                <Grid item xs={12} sm={6} lg={4} key={product.uri}>
                                                    <Styled.AddProductButton
                                                        key={product.uri}
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={(event) => addProduct(product)}
                                                        disabled={product.inStock === false || !editable}
                                                        // style={{ display: 'flex' }}
                                                        // startIcon={<PlaylistAddIcon />}
                                                    >
                                                        <div>
                                                            {product.label}
                                                            <div
                                                                style={{ fontSize: 12, lineHeight: 1, fontWeight: 200 }}
                                                            >
                                                                {product.longName}
                                                            </div>
                                                        </div>
                                                        {/* {product.legend && (
                                                            <img
                                                                src={`/images/products/${product.uri}.png`}
                                                                style={{ width: 300, display: 'block', marginTop: 8 }}
                                                            />
                                                        )} */}
                                                    </Styled.AddProductButton>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Styled.AddProductContainer>
                                );
                            })}
                        </div>
                    }

                    {Object.entries(data).map(([uri, products], productIndex) => {
                        const productDef = catalog.find((x) => x.uri === uri);
                        return (
                            <div key={`${uri}`} id={uri}>
                                {productDef && (products as any[]).length > 0 && (
                                    <Styled.RfqFieldset disabled={!editable}>
                                        <Styled.ProductName>{productDef.label}</Styled.ProductName>

                                        {productDef.legend && <ProductPreview uri={uri}></ProductPreview>}

                                        <Badge color="secondary" badgeContent={(products as any[]).length}>
                                            <Typography variant="h5">
                                                <Trans t={t}>Products</Trans>
                                            </Typography>
                                        </Badge>

                                        <div className={`product-cards-container`}>
                                            {(products as any[]).map((product: any, index) => {
                                                const id =
                                                    getValues(`${uri}[${index}]._id`) ||
                                                    getNewProductId(rfq.id, uri, productIndex, index);
                                                const className =
                                                    currentProductEdit?.product._id === id ? 'active' : '';
                                                return (
                                                    <ProductCardForm
                                                        id={id}
                                                        isActive={currentProductEdit?.product._id === id}
                                                        onClick={(e) => handleProductSpecEdit(e, uri, product, id)}
                                                        className={className}
                                                        uri={uri}
                                                        key={`${uri}-${index}`}
                                                        product={product}
                                                        index={index}
                                                        productDef={productDef}
                                                        onDelete={() => setShowDeleteDialog({ uri, index })}
                                                    />
                                                );
                                            })}
                                        </div>

                                        <Button
                                            style={{
                                                marginTop: 8,
                                                color: '#000',
                                            }}
                                            variant="outlined"
                                            onClick={() => addProduct(productDef)}
                                            startIcon={<AddCircle />}
                                        >
                                            <Trans t={t}>Add another product</Trans> {productDef.label}
                                        </Button>
                                    </Styled.RfqFieldset>
                                )}
                            </div>
                        );
                    })}
                </div>

                <footer style={{ textAlign: 'center', marginTop: 60 }}>
                    {canRequestQuote && (
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={onSubmitRFQ}
                            style={{ marginBottom: 32 }}
                        >
                            <Trans t={t}>Get an Instant Quote</Trans>
                        </Button>
                    )}
                </footer>
            </form>
        </FormProvider>
    );
};

export const RFQForm = () => {
    const { currentUser } = useCurrentUser();
    const { rfqId } = useParams<{ rfqId: string }>();
    const [rfq, setRfq] = React.useState<any>(undefined);
    const [editable, setEditable] = React.useState<any>(false);
    // const [canEdit, setCanEdit] = React.useState<any>(true);
    const [version, setVersion] = React.useState<number>(0);
    const [updatedAt, setUpdatedAt] = React.useState<string>('');
    const { setProject } = useProjects();
    const { t } = useTranslation();

    async function fetchRfq() {
        const response = (await API.graphql(graphqlOperation(getRfq, { id: rfqId }))) as {
            data: GetRfqQuery;
        };
        setRfq(response.data.getRFQ);
    }
    React.useEffect(() => {
        if (rfq) {
            setVersion(rfq.version);
            setUpdatedAt(rfq.updatedAt);
            setProject(rfq.project);
            setEditable(rfq.creator.id === currentUser.id);
        }
    }, [rfq, currentUser]);

    // const handleEditClick = () => {
    //     setEditable(true);
    // };

    React.useEffect(() => {
        if (currentUser) {
            fetchRfq();
        }
    }, [currentUser]);

    return (
        <div>
            {rfq && (
                <Breadcrumbs
                    crumbs={[
                        { name: <ProjectName project={rfq.project} />, path: `/job/${rfq.project.id}` },
                        { name: `RFQ #${rfq.id}`, path: './' },
                    ]}
                />
            )}
            {rfq && (
                <>
                    <header style={{ background: '#efefef', borderRadius: '10px', padding: '15px' }}>
                        {/* <header>
                        {canEdit && !editable && (
                            <Button
                                onClick={handleEditClick}
                                variant="contained"
                                color="primary"
                                startIcon={<EditIcon />}
                            >
                                Edit
                            </Button>
                        )}
                    </header> */}
                        {/* <h2 style={{ marginTop: 0 }}>
                        </h2> */}
                        <Typography variant="h4">
                            <Trans t={t}>Request for quotation</Trans> #{rfq.id}
                        </Typography>
                        <Typography paragraph>
                            <Trans t={t}>Last update at</Trans>: {formatDate(updatedAt, 'LLL')}
                            <br />
                            <Trans t={t}>Revision</Trans>: {version}
                            <br />
                            <Trans t={t}>Sizing type</Trans>:{' '}
                            {rfq.sizingType === SizingType.VOLUMES && <Trans t={t}>Volumes</Trans>}
                            {(!!!rfq.sizingType || rfq.sizingType === SizingType.DIMENSIONS) && (
                                <Trans t={t}>Dimensions</Trans>
                            )}
                            {rfq.sizingType === SizingType.CONSTRAINTS && <Trans t={t}>Constraints</Trans>}
                            <br />
                            <Trans t={t}>Status</Trans>: <RFQStatus rfq={rfq} />
                        </Typography>
                    </header>
                    {!rfq.sizingType && <RFQSelectSizingType rfq={rfq} updateCallback={fetchRfq} />}
                    {rfq.sizingType && (
                        <RFQFormContent
                            rfq={rfq}
                            editable={editable}
                            setUpdatedAt={setUpdatedAt}
                            setVersion={setVersion}
                        />
                    )}
                </>
            )}
        </div>
    );
};

const RFQSelectSizingType = (props: any) => {
    const { t } = useTranslation();
    const [sizingType, setSizingType] = React.useState('DIMENSIONS');
    const { rfq, updateCallback } = props;

    const updateSizingType = React.useCallback(async () => {
        const response = (await API.graphql(graphqlOperation(updateRfq, { input: { id: rfq.id, sizingType } }))) as any;
        updateCallback();
    }, [sizingType]);

    const handleSizingTypeChange = React.useCallback(
        (e) => {
            const value = e.target.value;
            setSizingType(value);
        },
        [setSizingType],
    );
    const handleConfirm = () => {
        updateSizingType();
    };

    return (
        <>
            <FormControl component="fieldset">
                <Typography variant="h6" gutterBottom style={{ marginTop: 32 }}>
                    <Trans t={t}>Sizing type</Trans>
                </Typography>

                <RadioGroup name="sizingType" value={sizingType} onChange={handleSizingTypeChange}>
                    <FormControlLabel
                        value="DIMENSIONS"
                        control={<Radio />}
                        label="Vous connaissez les dimensions des appuis néoprène"
                    />
                    <FormControlLabel
                        value="VOLUMES"
                        control={<Radio />}
                        label="Vous connaissez le volume des appuis néoprène"
                    />
                    <FormControlLabel
                        value="CONSTRAINTS"
                        control={<Radio />}
                        label="Vous ne connaissez ni les dimensions ni les volumes des appuis néoprènes. Un calcul de dimensionnement est nécessaire pour le chiffrage."
                    />
                </RadioGroup>
            </FormControl>

            {/* {sizingType === 'DIMENSIONS' && (
                <Typography paragraph variant="body2">
                     Le dimensionnement est nécessaire pour le chiffrage. Le devis instantané est disponible si vous
                    n’avez pas besoin d’options. Dans le cas où des options sont nécessaires, vous recevrez le chiffrage
                    dans les plus brefs délais! 
                </Typography>
            )}
            {sizingType === 'CONSTRAINTS' && (
                <Typography paragraph variant="body2">
                     Le dimensionnement est nécessaire pour le chiffrage. Le devis instantané n’est donc pas disponible.
                    Vous recevrez le chiffrage dans les plus brefs délais!
                </Typography>
            )}
           */}

            <FormHelperText style={{ marginTop: 30 }}>
                Vous ne pouvez pas modifier ce choix ultérieurement mais vous pouvez créer plusieurs demandes de
                chiffrage par Job
            </FormHelperText>

            <div style={{ marginTop: 10 }}>
                <Button onClick={handleConfirm} variant="contained" color="primary" size="large">
                    {t('Next')}
                </Button>
            </div>
        </>
    );
};

type DeleteProductAlertDialogProps = {
    onAgree: any;
    open: boolean;
};

function DeleteProductAlertDialog(props: DeleteProductAlertDialogProps) {
    const [open, setOpen] = React.useState(props.open);
    // event.stopPropagation();

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        // setOpen(false);
        props.onAgree(false);
    };

    const handleAgree = () => {
        // handleClose();
        if (props.onAgree) {
            props.onAgree(true);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Confirm product deletion?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Delete this product from RFQ</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAgree} color="primary" autoFocus variant="contained">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
