import { Badge } from '@material-ui/core';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CreateDocumentFolderMutation, DocumentFolderByUriQuery } from '../../../API';
import { createDocumentFolder, createDocument, deleteDocument } from '../../../graphql/mutations';
import { onCreateDocumentByFolderUri } from 'src/graphql/subscriptions';
import { documentFolderByUri } from '../../../graphql/queries';
import { getUUID, sortByDate } from '../../../helpers';
import useCurrentUser from '../../../hooks/useCurrentUser';
import DocumentFolderItems from './DocumentFolderItems';

import * as Styled from './styles';

const getColor = (props: any) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#999';
};

const Container = styled.div`
    // flex: 1;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    padding: 16px;
    margin-bottom: 16px;
    border-width: 1px;
    border-radius: 4px;
    border-color: ${(props) => getColor(props)};
    border-style: ${(props: any) => (props.isDragActive ? 'dashed' : 'solid')};
`;

type StyledDropzoneProps = {
    onDrop: any;
    children: React.ReactNode;
};
function StyledDropzone({ onDrop, children }: StyledDropzoneProps) {
    const { t } = useTranslation();

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        // accept: '*/*',
        onDrop: onDrop,
        noClick: true,
    });

    return (
        <Container className="docfolder-container" {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            <div>{children}</div>
            <p>
                <Trans t={t}>Drag and drop files here to upload</Trans>
            </p>
        </Container>
    );
}

type DocumentFolderProps = {
    onUpload?: (doc: any) => void;
    projectId?: string;
    uri: string;
    label: string;
    folder?: any;
};
export const DocumentFolder = (props: DocumentFolderProps) => {
    const [folder, setFolder] = React.useState<any>(null);
    const folderRef = React.useRef(folder);
    const { currentUser } = useCurrentUser();
    const [transfers, setTransfers] = React.useState<any>([]);

    // React.useEffect(() => {
    // }, [files]);

    const uploadFile = React.useCallback(
        (file: any, folder: any): void => {
            // SetS3Config('my-test-bucket-amplify', 'protected');
            console.log('uploading file', file);
            const fileId = getUUID();
            const fileName = file.name;

            const newTransfer = { fileId, fileName, progess: 0 };
            setTransfers([...transfers, newTransfer]);

            Storage.configure({ level: 'protected' });
            Storage.put(`documents/${fileId}-${file.name}`, file, {
                contentType: file.type,
                progressCallback(progress: any) {
                    const percentage = progress.loaded / progress.total;
                    console.log(`Uploaded percentage: ${percentage}%`);
                },
            })
                .then(async (result: any) => {
                    console.log(result);
                    const doc = {
                        id: fileId,
                        name: fileName,
                        S3Key: String(result.key),
                        contentType: file.type,
                        projectId: props.projectId,
                        folderId: folder.id,
                        folderUri: folder.uri,
                        size: file.size,
                        creatorId: currentUser.id,
                    };
                    // this.upload = null;
                    // add document
                    await API.graphql(graphqlOperation(createDocument, { input: doc }));
                    // this.setState({ response: 'Success uploading file!' });

                    setTransfers([...transfers.filter((x: any) => x.fileId !== fileId)]);

                    // refresh state : todo subscription
                    // fetchFolder();

                    if (props.onUpload) {
                        props.onUpload(doc);
                    }
                })
                .catch((err) => {
                    // this.setState({ response: `Cannot uploading file: ${err}` });
                    console.error(err);
                });
        },
        [transfers],
    );

    const handleDeleteDocument = async (doc: any) => {
        //delete s3
        await Storage.remove(doc.S3Key);
        await API.graphql(graphqlOperation(deleteDocument, { input: { id: doc.id } }));
        fetchFolder();
    };

    const onDrop = React.useCallback(
        (acceptedFiles, rejectedFiles) => {
            console.log(folder, acceptedFiles, rejectedFiles);

            // setFiles(acceptedFiles);

            acceptedFiles.forEach((file: any) => {
                //   const reader = new FileReader()

                //   reader.onabort = () => console.log('file reading was aborted')
                //   reader.onerror = () => console.log('file reading has failed')
                //   reader.onload = () => {
                //   // Do whatever you want with the file contents
                //     const binaryStr = reader.result
                //     console.log(binaryStr)
                //   }
                //   reader.readAsArrayBuffer(file)
                uploadFile(file, folder);
            });
        },
        [folder],
    );

    const fetchFolder = React.useCallback(async function () {
        const response = (await API.graphql(graphqlOperation(documentFolderByUri, { uri: props.uri }))) as {
            data: DocumentFolderByUriQuery;
        };
        console.log('fetchFolder', props, response);
        if (
            response.data.documentFolderByUri &&
            response?.data?.documentFolderByUri?.items &&
            response?.data?.documentFolderByUri?.items?.length > 0
        ) {
            setFolder(response.data.documentFolderByUri.items[0]);
            folderRef.current = response.data.documentFolderByUri.items[0];
        } else {
            createFolder();
        }
    }, []);

    async function createFolder() {
        const response = (await API.graphql(
            graphqlOperation(createDocumentFolder, {
                // projectId: props.projectId,
                input: { uri: props.uri, countDocuments: 0 },
            }),
        )) as {
            data: CreateDocumentFolderMutation;
        };
        console.log('createFolder', response);
        setFolder(response.data.createDocumentFolder);
    }

    const pushDocument = React.useCallback(
        (doc) => {
            const updated = { ...folderRef.current, documents: { items: [...folderRef.current.documents.items, doc] } };
            folderRef.current = updated;
            setFolder(updated);
        },
        [folder],
    );
    const subscribeFolder = async () => {
        const subscription = (API.graphql(
            graphqlOperation(onCreateDocumentByFolderUri, { folderUri: props.uri }),
        ) as any).subscribe({
            next: ({ provider, value }: { provider: any; value: any }) => {
                pushDocument(value.data.onCreateDocumentByFolderUri);
                console.log('new doc', { provider, value });
            },
            error: (error: any) => console.warn(error),
        });
    };

    React.useEffect(() => {
        fetchFolder();
        subscribeFolder();
    }, [fetchFolder]);

    const folderIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
                fill="#000"
                d="M19,5.5H12.72l-.32-1a3,3,0,0,0-2.84-2H5a3,3,0,0,0-3,3v13a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V8.5A3,3,0,0,0,19,5.5Zm1,13a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5.5a1,1,0,0,1,1-1H9.56a1,1,0,0,1,.95.68l.54,1.64A1,1,0,0,0,12,7.5h7a1,1,0,0,1,1,1Z"
            />
        </svg>
    );
    const countDocuments = folder?.documents?.items?.length;

    return (
        <Styled.DocumentFolder className={'docfolder'}>
            <StyledDropzone onDrop={onDrop}>
                <legend style={{ display: 'flex', alignItems: 'center' }}>
                    <Badge color="secondary" badgeContent={countDocuments}>
                        {folderIcon}
                    </Badge>
                    <span style={{ display: 'inline-block', marginLeft: 10 }}>{props.label}</span>
                </legend>
                <div className="transfers">
                    {transfers.map((transfer: any) => (
                        <svg
                            key={transfer.fileId}
                            xmlns="http://www.w3.org/2000/svg"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="#6563ff"
                                d="M18.42,8.22A7,7,0,0,0,5.06,10.11,4,4,0,0,0,6,18a1,1,0,0,0,0-2,2,2,0,0,1,0-4,1,1,0,0,0,1-1,5,5,0,0,1,9.73-1.61,1,1,0,0,0,.78.67,3,3,0,0,1,.24,5.84,1,1,0,0,0,.5,1.94,5,5,0,0,0,.17-9.62Zm-5.71,2.07a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-3,3a1,1,0,0,0,1.42,1.42L11,13.41V19a1,1,0,0,0,2,0V13.41l1.29,1.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                            />
                        </svg>
                    ))}
                </div>

                {/* {folder && folder?.documents?.items.length === 0 && <div>Empty</div>} */}
                {folder && folder?.documents?.items && (
                    <DocumentFolderItems
                        items={sortByDate(folder?.documents?.items, 'createdAt', 'ASC')}
                        onDeleteDocument={handleDeleteDocument}
                    />
                )}
            </StyledDropzone>
        </Styled.DocumentFolder>
    );
};
