import { API, graphqlOperation } from 'aws-amplify';

import { createRfq } from 'src/graphql/mutations';
import { getUUID } from 'src/helpers';
// import { listProjects } from 'src/graphql/queries';
import { ListProjectMembersQuery, CreateRfqMutation, GetRfqQuery } from 'src/API';
// import { ListProjectsQuery } from 'src/API';
import { listProjectMembers, getRfq } from 'src/graphql/queries';
import { USER_STATUS, USER_ROLES } from 'src/constants';
import { RFQ_STATUS } from 'src/constants';
// import { getUUID } from 'src/helpers';
// import { CreateUserMutation } from 'src/API';

type WithId<T> = { id: string } & T;

export const fetchProjects = async function (currentUser: any): Promise<Array<any> | null> {
    try {
        const response = (await API.graphql(
            graphqlOperation(listProjectMembers, {
                filter: { userId: { eq: currentUser.id } },
            }),
        )) as {
            data: ListProjectMembersQuery;
        };
        if (response?.data?.listProjectMembers && response?.data?.listProjectMembers?.items) {
            return response?.data?.listProjectMembers?.items.map((x: any) => x.project);
        } else {
            return [];
        }
    } catch (err) {
        console.log('error fetching projects', err);
    }
    return null;
};

const getProjectMember = async function (filter: any): Promise<any | null> {
    const projectMembers = (await API.graphql(
        graphqlOperation(listProjectMembers, {
            filter,
        }),
    )) as {
        data: ListProjectMembersQuery;
    };
    if (projectMembers?.data?.listProjectMembers && projectMembers?.data?.listProjectMembers?.items) {
        return projectMembers?.data?.listProjectMembers?.items[0];
    } else {
        return null;
    }
};

export const createRFQ = async function (currentUser: any, projectId: string): Promise<any | null> {
    const response = {};
    try {
        const { id: projectMemberId } = (await getProjectMember({
            and: [{ projectId: { eq: projectId } }, { userId: { eq: currentUser.id } }],
        })) as WithId<any>;

        const projectMemberIds = [projectMemberId];

        const buyerProjectMember = (await getProjectMember({
            and: [{ projectId: { eq: projectId } }, { role: { eq: USER_ROLES.BROKER } }],
        })) as WithId<any>;
        if (buyerProjectMember) {
            const { id: projectMemberBrokerId } = buyerProjectMember;
            projectMemberIds.push(projectMemberBrokerId);
        }

        const id = getUUID();
        const response = (await API.graphql(
            graphqlOperation(createRfq, {
                input: {
                    id,
                    version: 1,
                    projectId: projectId,
                    creatorId: currentUser.id,
                    projectMemberIds,
                    status: RFQ_STATUS.NEW,
                },
            }),
        )) as {
            data: CreateRfqMutation;
        };

        return response.data.createRFQ;
    } catch (err) {
        console.log('error creating RFQ', err);
    }
    return response;
};

export const getCurrentUserProjectMember = async function (currentUser: any, projectId: string): Promise<any | null> {
    try {
        const response = (await API.graphql(
            graphqlOperation(listProjectMembers, {
                filter: { and: [{ userId: { eq: currentUser.id } }, { projectId: { eq: projectId } }] },
            }),
        )) as {
            data: ListProjectMembersQuery;
        };
        if (response?.data?.listProjectMembers && response?.data?.listProjectMembers?.items) {
            return response?.data?.listProjectMembers?.items[0];
        } else {
            return null;
        }
    } catch (err) {
        console.log('error fetching projects', err);
    }
};

export const getCurrentUserProjectMembers = async function (currentUser: any): Promise<any | null> {
    try {
        const response = (await API.graphql(
            graphqlOperation(listProjectMembers, {
                filter: { userId: { eq: currentUser.id } },
            }),
        )) as {
            data: ListProjectMembersQuery;
        };
        if (response?.data?.listProjectMembers && response?.data?.listProjectMembers?.items) {
            return response?.data?.listProjectMembers?.items;
        } else {
            return null;
        }
    } catch (err) {
        console.log('error fetching projects', err);
    }
};

export const fetchRFQ = async function (currentUser: any, rfqId: string): Promise<any | null> {
    try {
        const response = (await API.graphql(graphqlOperation(getRfq, { id: rfqId }))) as {
            data: GetRfqQuery;
        };
        if (response?.data?.getRFQ) {
            return response?.data?.getRFQ;
        } else {
            return [];
        }
    } catch (err) {
        console.log('error fetching projects', err);
    }
    return null;
};
