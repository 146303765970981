import React from 'react';
import Flag from 'react-flagkit';
import styled from 'styled-components';

type Props = {
    project: any;
};
const StyledWrapper = styled.span`
    display: flex;
    align-items: center;
    img {
        margin-right: 10px;
        height: 28px;
        width: auto;
        border-radius: 4px;
    }
`;

const ProjectName = ({ project }: Props) => {
    return (
        <StyledWrapper>
            <Flag country={project.country} />
            <span>{project.name}</span>
        </StyledWrapper>
    );
};
export default ProjectName;
